import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { SubsectionService } from "../../../services/subsection.service";
import { SimpleUser } from "../../../interfaces";
import { ProjectService } from "../../../services/project.service";

@Component({
  selector: 'mtm-asset-info',
  templateUrl: './asset-info.component.html',
  styleUrls: ['./asset-info.component.scss']
})
export class AssetInfoComponent implements OnInit {
  @Input() file: any = null;

  private _visible: boolean = false;
  get visible(): boolean {
    return this._visible;
  }

  @Input() set
    visible(value: boolean) {
    this._visible = value;
    if (value) {
      this.renderer.addClass(this.elementRef.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'active');
    }
  }
  @Output() visibleChange = new EventEmitter<boolean>();


  elementRef = inject(ElementRef);
  renderer = inject(Renderer2);
  projectService = inject(ProjectService);
  participants: SimpleUser[] = [];

  ngOnInit() {
    if (this.projectService.project) {
      this.participants = this.projectService.project.participants;
    }
  }

  hideAssetInfo() {
    this.visible = false;
  }
}
