import { Component, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { HelperService } from "../../../shared/services/helper.service";
import { ActiveSubsectionState, SubsectionService } from "../../../shared/services/subsection.service";
import { OptionInfo, OptionVersion, OptionVersionSimple } from "../../../shared/interfaces";
import { ProjectService } from "../../../shared/services/project.service";
import { forkJoin, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NgbActiveModal, NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { OverlayService } from "../../../shared/services/overlayService";
import { TranslatePipe } from "../../../shared/pipes/translate.pipe";
import { EmitterService } from "../../../shared/services/emitter.service";
import { OptionCreatedEvent, OptionUpdatedEvent, VersionUpdatedEvent } from "../../../shared/models";
import {
  FileTransferService,
  listenerFileUploadCompleted,
  MTMCustomFile, SignedURLUploadRequest,
  TransferStatus, TransferType
} from "../../../shared/services/signed-url";
import { AuthService } from "../../../shared/services/auth.service";
import { MTMFileDownload } from "../../../shared/services/signed-url/mtm-file-download";
import { NotificationService } from "../../../shared/services/notification.service";
import {
  SubsectionVersionListComponent, SubsectionVersionListResult
} from "app/shared/components/subsection-version-list/subsection-version-list.component";
import _ from "lodash";
import { FileDisplayNamePipe } from "../../../shared/pipes/file-display-name.pipe";
import { DirectoryService } from "../../../shared/services/directory.service";


type FileToDelete = {
  fileId: string;
  version: number;
}

type FileDisplayInfo = {
  fileId: string;
  displayName: string;
  caption: string; //display name without timestamp & extension
  originalCaption: string;
  version: number;
  newDisplayName?: string;
}

@Component({
  selector: 'subsection-option-edit',
  templateUrl: './subsection-option-edit.component.html',
  styleUrls: ['./subsection-option-edit.component.scss'],
})
export class SubsectionOptionEditComponent implements OnInit, OnDestroy {
  @Input() optionId: string = '';
  @Input() topicId: string = '';
  @Input() versionNumber: number = 1;
  @Input() isNewVersion: boolean = false;

  private fb = inject(FormBuilder);
  private projectService = inject(ProjectService);
  private subsectionService = inject(SubsectionService);
  private activeModal = inject(NgbActiveModal);
  private overlayService = inject(OverlayService);
  private translatePipe = inject(TranslatePipe);
  private authService = inject(AuthService);
  private transferService = inject(FileTransferService);
  private notificationService = inject(NotificationService);
  private modalService = inject(NgbModal);
  private fileDisplayPipe = inject(FileDisplayNamePipe);
  private directoryService = inject(DirectoryService);

  subsectionState: ActiveSubsectionState = null;
  optionForm = this.fb.group({
    title: [null, {
      validators: [Validators.required, Validators.maxLength(100)],
    }],
    description: ['', [Validators.maxLength(300)]]
  });
  isLoading: boolean = false;
  currentOption: OptionInfo | Record<string, never> = {};
  originalOption: OptionInfo | null = null;
  currentVersion: OptionVersion = null;
  versions: Record<number, OptionVersion> = {};
  originalVersions: Record<number, OptionVersion> = {};
  currentFiles: any[] = [];
  newFiles: MTMCustomFile[] = [];
  fileDisplayRecord: Record<string, FileDisplayInfo> = {};

  private ngUnsubscribe = new Subject<void>();
  draggingKey: string = 'option-files';
  filesLoading: boolean = false;

  private versionsToDelete: number[] = [];
  private expectedNewVersion: number = 0;
  private filesToDelete: FileToDelete[] = [];

  constructor() {

  }

  get versionFilterParam(): number {
    return this.currentVersion?.versionNumber || this.versionNumber;
  }

  ngOnInit() {
    this.subsectionService.subsection$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: state => {
          this.subsectionState = state;
        }
      })

    listenerFileUploadCompleted
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (file: MTMCustomFile) => {
          this.syncFiles(file.dbFileObject);
          EmitterService.get(OptionUpdatedEvent).emit(this.currentOption);
        }
      })


    if (this.optionId) {
      this.loadOption();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }


  loadOption() {
    this.isLoading = true;
    const projectId = this.projectService.project.id;
    const sectionId = this.subsectionState.section.id.toString();
    const subsectionId = this.subsectionState.subsection.id.toString();

    const requests: Observable<any>[] = [this.subsectionService.getOption(projectId, sectionId, subsectionId, this.optionId)];

    if (!this.isNewVersion) {
      requests.push(this.subsectionService.getVersion({
        projectId: projectId,
        sectionId: sectionId,
        subsectionId: subsectionId,
        itemId: this.optionId,
        versionNumber: this.versionNumber
      }));
    }

    forkJoin(requests).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          const option = result[0];
          this.optionForm.patchValue({
            title: option.title,
            description: option.description
          });
          if (option.simpleVersions) {
            option.simpleVersions.sort((v1, v2) => v1.order - v2.order);
          }
          this.currentOption = option;
          if (!this.isNewVersion) {
            this.currentVersion = result[1];
            this.subsectionService.sortFilesByFileOrder(this.currentVersion);
            this.updateFileVM();
            this.versions[this.versionNumber] = this.currentVersion;
            this.originalVersions[this.versionNumber] = JSON.parse(JSON.stringify(this.currentVersion));
            this.currentFiles = this.currentVersion.files;
          } else {
            this.createNewVersion();
          }

          this.originalOption = JSON.parse(JSON.stringify(option));
          this.monitorFiles();
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          this.overlayService.showError(this.translatePipe.transform('versioning_edit_loadOptionFailed'));
          this.activeModal.dismiss();
        }
      });
  }

  handleFileUpload(files: any) {
    const filesToUpload = files.files;
    if (!filesToUpload) {
      return;
    }

    this.prepareFilesForUpload(filesToUpload);
  }

  private updateFileVM() {
    this.currentVersion.files.forEach(file => {
      if (this.fileDisplayRecord[file.id]) {
        return;
      }
      const caption = this.fileDisplayPipe.transform(file.displayName, true);

      this.fileDisplayRecord[file.id] = {
        fileId: file.id,
        displayName: file.displayName,
        caption,
        originalCaption: caption,
        version: this.currentVersion.versionNumber
      };
    });
  }

  private continueUploading() {
    const errorUploadedFiles = this.newFiles.filter(file => file.uploadStatusCode === TransferStatus.ERROR)
    if (this.newFiles.length > 0) {
      this.startVersionedUpload();
    } else if (errorUploadedFiles && errorUploadedFiles.length) {
      this.newFiles = errorUploadedFiles;
      this.startVersionedUpload();
    }
  }

  private syncFiles(file: any) {
    if (this.currentFiles.some(f => f.id === file.id)) {
      return;
    }
    this.currentFiles.push(file);
  }

  private areFileNamesValid(): boolean {
    return this.currentFiles.every(file => {
      const displayInfo = this.fileDisplayRecord[file.id];
      return !!displayInfo.caption.trim();
    });
  }

  submit() {
    HelperService.checkFormFields(this.optionForm);
    if (this.optionForm.invalid || this.isLoading || !this.areFileNamesValid()) {
      return;
    }

    const projectId = this.projectService.project.id;
    const sectionId = this.subsectionState.section.id.toString();
    const subsectionId = this.subsectionState.subsection.id.toString();
    const topicId = this.subsectionState.activeTopic.topicId;

    this.isLoading = true;
    const value: Partial<OptionInfo> = {
      project: projectId,
      section: sectionId,
      subsection: subsectionId,
      topicId: topicId,
      ...(this.optionId ? this.currentOption : {}),
      ...this.optionForm.value
    };

    const save$ = this.optionId ? this.subsectionService.updateOption(value) : this.subsectionService.createOption(value);
    save$
      .subscribe({
        next: (result) => {
          result.id = result.optionId;

          if (!this.currentOption.optionId) {
            if (!result.project && result.projectId) {
              result.project = result.projectId;
            }
            if (!result.section && result.sectionId) {
              result.section = result.sectionId;
            }
            if (!result.subsection && result.subSectionId) {
              result.subsection = result.subSectionId;
            }
            if (!result.topicId) {
              result.topicId = topicId;
            }

            this.currentOption = result;
            this.checkFilesToUpload();
          }
          this.monitorFiles();
          if (!result.simpleVersions?.length) {
            this.subsectionService.createNextVersion(projectId, sectionId, subsectionId, result.optionId)
              .subscribe({
                next: (version) => {
                  this.continueUploading();
                  this.cleanUp(result);
                },
                error: (error) => {
                  this.overlayService.showError(this.translatePipe.transform('versioning_edit_saveOptionFailed'));
                  this.isLoading = false;
                }
              })
          } else {
            this.checkNewVersionNeeded();
          }
        },
        error: (error) => {
          this.overlayService.showError(this.translatePipe.transform('versioning_edit_saveOptionFailed'));
          this.isLoading = false;
        }
      })
  }

  private cleanUp(result: OptionInfo) {
    this.overlayService.showSuccess(this.translatePipe.transform('dataSaved'));
    EmitterService.get(this.optionId ? OptionUpdatedEvent : OptionCreatedEvent).emit(result);
    EmitterService.get(VersionUpdatedEvent).emit({
      version: this.currentVersion,
    });
    this.activeModal.close();
  }

  cancel() {
    for (let i = this.newFiles.length - 1; i >= 0; i--) {
      if (this.newFiles[i].uploadStatusCode == TransferStatus.NEW) {
        this.deleteNewFile(this.newFiles[i]);
      }
    }
    this.activeModal.dismiss();
  }

  deleteNewFile(file: MTMCustomFile) {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    file.removeFromUploadingFiles(file);
    const fileIndex = this.newFiles.findIndex(f => f.fileLocalId == file.fileLocalId)
    if (fileIndex > -1) {
      this.newFiles.splice(fileIndex, 1);
    }
    this.transferService.cancelUpload(file);
    this.isLoading = false;
  }

  downloadFile(file: any) {
    const downloadItem = new MTMFileDownload();
    const userSettings = this.authService.getAuthUserSettings();
    if (userSettings?.uploadResumable && typeof userSettings?.uploadResumable === 'boolean') {
      downloadItem.isResumable = userSettings.uploadResumable;
    }
    downloadItem.name = file.name;
    downloadItem.url = file.signedURL;
    downloadItem.contentType = file.contentType;
    downloadItem.size = file.size;
    downloadItem.displayName = file.displayName;
    this.transferService.downloadFile(downloadItem);
  }

  deleteFile(file: any) {
    this.filesToDelete.push({
      fileId: file.id,
      version: this.currentVersion.versionNumber
    });
    const index = this.currentFiles.findIndex(f => f.id == file.id);
    this.currentFiles.splice(index, 1);
  }

  switchVersion(versionNumber: number) {
    const done = () => {
      const activeIndex = this.currentOption.simpleVersions.findIndex(v => v.versionNumber == versionNumber);
      const newActiveVersion = this.currentOption.simpleVersions[activeIndex];
      this.currentOption.simpleVersions.splice(activeIndex, 1);
      this.currentOption.simpleVersions.unshift(newActiveVersion);
      this.currentOption.simpleVersions.forEach((v, index) => {
        v.order = index;
      });
      this.updateFileVM();
    };

    if (this.versions[versionNumber]) {
      const version = this.versions[versionNumber];
      this.currentVersion = version;
      this.currentFiles = version.files;
      done();
    } else {
      this.subsectionService.getVersion({
        projectId: this.currentOption.project,
        sectionId: this.currentOption.section,
        subsectionId: this.currentOption.subsection,
        itemId: this.currentOption.optionId,
        versionNumber
      }).pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (version) => {
            this.subsectionService.sortFilesByFileOrder(version);
            this.currentVersion = version;
            this.currentFiles = version.files;
            this.versions[versionNumber] = version;
            done();
          }
        });
    }
  }

  dragFile(event: DragEvent & { dataTransfer?: DataTransfer }, file: any) {
    if (!file) {
      return;
    }

    event.dataTransfer.setData(`${this.draggingKey}_fileId`, file.id);
  }

  dragOver(event: DragEvent) {
    (event as any).preventDefault();
  }

  dropFile(event: DragEvent & { dataTransfer?: any }, file: any) {
    let fileId = event.dataTransfer.getData(`${this.draggingKey}_fileId`);
    let originalIndex = this.currentFiles.findIndex(f => f.id == fileId);
    let targetIndex = this.currentFiles.findIndex(f => f.id == file.id);
    const originalFile = this.currentFiles[originalIndex];
    this.currentFiles.splice(originalIndex, 1);
    let newTargetIndex = this.currentFiles.findIndex(f => f.id == file.id);
    if (originalIndex < targetIndex) {
      this.currentFiles.splice(newTargetIndex + 1, 0, originalFile);
    } else {
      this.currentFiles.splice(newTargetIndex, 0, originalFile);
    }
    originalFile.draggable = false;
    const orderDto = {};
    this.currentFiles.forEach((file, index) => {
      orderDto[index + 1] = file.id; // Returning a value from forEach is ignored. thats why remove return.
    });
  }

  showManageVersions() {
    const modalRef = this.modalService.open(SubsectionVersionListComponent, {
      backdrop: 'static',
      modalDialogClass: 'responsive-modal',
    });
    modalRef.componentInstance.projectId = this.currentOption.project;
    modalRef.componentInstance.sectionId = this.currentOption.section;
    modalRef.componentInstance.subsectionId = this.currentOption.subsection;
    modalRef.componentInstance.itemId = this.currentOption.optionId;
    modalRef.componentInstance.versions = [...this.currentOption.simpleVersions];
    modalRef.componentInstance.option = this.currentOption;
    modalRef.componentInstance.isPreviewOnly = true;
    modalRef.result.then((result: SubsectionVersionListResult) => {
      if (!result) {
        return;
      }

      if (result.type === 'reorder') {
        const orderedVersions = result.orderedIds;
        const allVersions = this.currentOption.simpleVersions.map(v => v.versionNumber);
        const versionNumbersToDelete = allVersions.filter(v => !orderedVersions.includes(v));
        const versionsToDelete = this.currentOption.simpleVersions.filter(v => versionNumbersToDelete.includes(v.versionNumber)).map(v => v.versionNumber);
        const newVersions = this.currentOption.simpleVersions.filter(v => orderedVersions.includes(v.versionNumber));
        newVersions.forEach((v, index) => {
          v.order = orderedVersions.indexOf(v.versionNumber);
        });
        newVersions.sort((v1, v2) => v1.order - v2.order);
        this.currentOption.simpleVersions = newVersions;
        this.switchVersion(orderedVersions[0]);
        this.newFiles.filter(f => versionsToDelete.includes(f.entity.version)).forEach(f => {
          this.deleteNewFile(f);
        });
        this.versionsToDelete.push(...versionsToDelete);
      }
    }).catch((error) => {

    });
  }

  createNewVersion() {
    const maxVersion = this.currentOption.simpleVersions.reduce((prev, current) => (prev < current.versionNumber) ?
      current.versionNumber : prev, 0) + 1;
    const newVersion: OptionVersion = {
      versionNumber: maxVersion,
      order: maxVersion,
      status: '',
      files: [],
      messages: [],
      votes: [],
      optionId: this.currentOption.optionId,
      project: this.currentOption.project,
      section: this.currentOption.section,
      subsection: this.currentOption.subsection,
      versionName: `Version ${maxVersion}`
    };
    const newSimpleVersion: OptionVersionSimple = {
      name: `Version ${maxVersion}`,
      versionNumber: maxVersion,
      order: 0,
      status: ''
    };
    this.expectedNewVersion = maxVersion;
    this.currentOption.simpleVersions.forEach(v => v.order++);
    this.currentOption.simpleVersions.unshift(newSimpleVersion);
    this.versions[maxVersion] = newVersion;
    this.currentVersion = newVersion;
    this.currentFiles = newVersion.files;
    this.isNewVersion = true;
  }

  private checkNewVersionNeeded() {
    if (this.expectedNewVersion > 0) {
      this.subsectionService.createNextVersion(this.currentOption.project,
        this.currentOption.section,
        this.currentOption.subsection,
        this.currentOption.optionId)
        .subscribe({
          next: (version) => {
            const actualVersion = version.versionNumber;
            if (actualVersion != this.expectedNewVersion) {
              this.newFiles.forEach(c => {
                if (c.entity.version === this.expectedNewVersion) {
                  c.entity.version = actualVersion;
                }
              });
              const newVersion = this.currentOption.simpleVersions.find(v => v.versionNumber == this.expectedNewVersion);
              if (newVersion) {
                newVersion.versionNumber = actualVersion;
              }
            }
            this.checkVersionDifferences();
          },
          error: (error) => {
            this.overlayService.showError(this.translatePipe.transform('versioning_edit_saveOptionFailed'));
            this.isLoading = false;
          }
        });
    } else {
      this.checkVersionDifferences();
    }
  }


  private checkVersionDifferences() {
    const obsList: Array<Observable<any>> = [];
    const reorderList: Array<Observable<any>> = [];

    if (this.versionsToDelete.length > 0) {
      obsList.push(this.subsectionService.deleteVersions(this.currentOption.project,
        this.currentOption.section,
        this.currentOption.subsection,
        this.currentOption.optionId,
        this.versionsToDelete));
    }

    const orderedIds = this.currentOption.simpleVersions.map(version => version.versionNumber);
    const previousOrderedIds = this.originalOption.simpleVersions.map(version => version.versionNumber);

    if (!_.isEqual(orderedIds, previousOrderedIds)) {
      obsList.push(this.subsectionService.reorderVersions(this.currentOption.project,
        this.currentOption.section, this.currentOption.subsection,
        this.currentOption.optionId, orderedIds));
    }

    if (this.filesToDelete.length > 0) {
      this.filesToDelete.forEach(f => {
        obsList.push(this.subsectionService.deleteFile(this.currentOption.project,
          this.currentOption.section,
          this.currentOption.subsection,
          this.currentOption.optionId,
          f.fileId,
          f.version));
      });
    }


    const filesToRename: FileDisplayInfo[] = [];

    Object.entries(this.versions).forEach(([versionNo, version]) => {
      const versionKey = parseInt(versionNo, 10);
      if (this.versionsToDelete.includes(versionKey)) {
        return;
      }
      if (version.files.length === 0) {
        return;
      }

      version.files.forEach(file => {
        if (this.filesToDelete.some(f => f.fileId === file.id)) {
          return;
        }

        const displayInfo = this.fileDisplayRecord[file.id];

        if (displayInfo.caption != displayInfo.originalCaption) {
          displayInfo.newDisplayName = displayInfo.displayName.replace(displayInfo.originalCaption, displayInfo.caption);
          filesToRename.push(displayInfo);
        }
      });

      if (versionKey in this.originalVersions) {
        const filesOrder = version.files.map(f => f.id);
        const originalOrder = this.originalVersions[versionKey].files.map(f => f.id);

        if (!_.isEqual(filesOrder, originalOrder)) {
          const orderDto = {};
          version.files.forEach((file, index) => {
            orderDto[index + 1] = file.id;
          });


          reorderList.push(this.subsectionService.reorderVersionFiles(this.currentOption.project,
            this.currentOption.section,
            this.currentOption.subsection,
            this.currentOption.optionId,
            versionKey,
            orderDto));

        }
      }
    });

    if (filesToRename.length > 0) {
      filesToRename.forEach(file => {
        obsList.push(this.directoryService.renameFolder(this.currentOption.project, file.fileId,
          file.newDisplayName));
      });
    }

    if (obsList.length > 0) {
      forkJoin(obsList)
        .subscribe({
          next: () => {
            this.reorderFiles(reorderList);
          }, error: () => {
            this.overlayService.showError(this.translatePipe.transform('versioning_edit_saveOptionFailed'));
          }
        });
    } else {
      this.reorderFiles(reorderList);
    }
  }

  private reorderFiles(reorderList: Array<Observable<any>>) {
    if (reorderList.length > 0) {
      forkJoin(reorderList)
        .subscribe({
          next: () => {
            this.continueUploading();
            this.cleanUp(this.currentOption as OptionInfo);
            this.isLoading = false;
          }, error: () => {
            this.overlayService.showError(this.translatePipe.transform('versioning_edit_saveOptionFailed'));
          }
        });
    } else {
      this.continueUploading();
      this.cleanUp(this.currentOption as OptionInfo);
      this.isLoading = false;
    }
  }

  //#region upload methods

  private prepareFilesForUpload(files: any[]) {
    const waitingFiles = this.newFiles.filter(f => f.uploadStatusCode === TransferStatus.WAITING);
    waitingFiles.forEach(file => {
      const newIndex = this.newFiles.findIndex(f => f == file);
      if (newIndex > -1) {
        this.newFiles.splice(newIndex, 1);
      }
    });

    for (let i = 0; i < files.length; i++) {
      let file = new File([files[i]], files[i].name, { type: files[i].type });
      if (this.newFiles.findIndex(k => k.fileName == files[i].name) < 0) {
        let newMTMCustomFile = this.createMTMCustomFile(file, i, false);

        if (this.currentOption && !newMTMCustomFile.entity.id) {
          newMTMCustomFile.entity.id = this.currentOption.optionId;
        }
        newMTMCustomFile.entity.version = this.currentVersion?.versionNumber || this.versionNumber;
        this.newFiles.push(newMTMCustomFile);
      }
    }

  }

  private checkFilesToUpload() {
    this.newFiles.forEach(file => {
      if (!file.entity.id && this.currentOption) {
        file.entity.id = this.currentOption?.optionId;
      }
      if (!file.entity.version) {
        file.entity.version = this.currentVersion.versionNumber || this.versionNumber;
      }
    });
  }

  private createMTMCustomFile(file, index, fileAttachForChat = null): MTMCustomFile {
    let newMTMCustomFile = new MTMCustomFile(file, index);
    newMTMCustomFile.postUploadPayload = fileAttachForChat;
    return newMTMCustomFile;
  }

  private isResumableUploadActivated(): boolean {
    const userSettings = this.authService.getAuthUserSettings();
    return userSettings?.uploadResumable || false;
  }

  getAPIUploadPath(): string {
    return `/api/projects/${this.projectService.project.id}/sections/${this.currentOption?.section}/subsections/${this.currentOption?.subsection}/v-items/${this.currentOption?.optionId}/`
  }


  getPreUploadURL(version: number = 0): string {
    let versionNumber = version;
    if (versionNumber === 0) {
      versionNumber = this.currentVersion?.versionNumber || this.versionNumber;
    }
    const isResumable = this.isResumableUploadActivated();
    const url = this.getAPIUploadPath() + (isResumable ? 'preUploadFilesVR' : 'preUploadFiles')
      + `?versionNumber=${versionNumber}`;

    return url;
  }

  getPostUploadURL(version: number = 0): string {
    let versionNumber = version;
    if (versionNumber === 0) {
      versionNumber = this.currentVersion?.versionNumber || this.versionNumber;
    }
    return this.getAPIUploadPath() + `postUploadFiles?versionNumber=${versionNumber}`;
  }

  monitorFiles() {
    this.newFiles = this.transferService.transferMonitor.monitor({
      newFiles: this.newFiles,
      currentFiles: this.currentFiles,
      origin: this.getPreUploadURL(),
      entity: this.currentOption
    });
  }

  startVersionedUpload() {
    this.startBasicVersionedUpload();
  }

  startBasicVersionedUpload() {
    const uploadRecords: Record<number, MTMCustomFile[]> = {};
    this.newFiles.filter(file => file.uploadStatusCode === TransferStatus.NEW).forEach(file => {
      if (!uploadRecords[file.entity.version]) {
        uploadRecords[file.entity.version] = [];
      }
      uploadRecords[file.entity.version].push(file);
    });
    Object.entries(uploadRecords).forEach(([version, files]) => {
      const intVersion = parseInt(version, 10);
      const request: SignedURLUploadRequest = {
        files,
        uploadType: TransferType.SignedURL,
        preUploadUrl: this.getPreUploadURL(intVersion),
        postUploadUrl: this.getPostUploadURL(intVersion),
        parentId: this.currentOption.optionId
      };
      this.transferService.uploadFiles(request);
    });
  }

  //#endregion
}
