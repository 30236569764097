<div class="image-annotation-component" [class.private]="privateCondition != null">
  <div class="toolbar" [hidden]="checkInvalidUser() || hideAnnotationToolbar">
        <div class="d-flex flex-column w-100">
            <div id="pallete"
                class="p-2 toolbarPallete hidden d-inline-flex flex-wrap align-items-center justify-content-xl-between">
                <div class="annotation-mode-toggle d-flex align-items-center h-100" title>
                    <ui-switch switchColor="#43CB9A" defaultBgColor="#FFFFFF" size="small"
                        class="mx-auto annotation-mode-switch" [(ngModel)]="annotationToolbarActive"
                        (ngModelChange)="toggleAnnotationToolbarDisplay(annotationToolbarActive)">
                    </ui-switch>
                </div>
                <!-- <div class="colorComp">
                    <span class="tooltiptext color" directiveCommentMinButtons>{{ 'selectColorAnnotation' | translate
                        }}</span>
                    <div id="colorIcon" class="toolbarOptions d-flex align-items-center ml-auto"
                        (click)="isColorOpen=!isColorOpen">
                        <div class="selected-color"></div>
                        <div class="caret ml-2" [ngClass]="{ 't-open': isColorOpen, 't-close': !isColorOpen }">
                            <i class="fa fa-play" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="separator"></div>
                </div> -->
                <div class="drawComp d-flex flex-wrap justify-content-center"
                    [ngClass]="{'disabled': !annotationToolbarActive}">
                    <div id="cursorIcon"
                        [ngClass]="{ 'active': activeShape==='cursor', 'disabledButton': !annotatingMode && !editingMode }"
                        class="toolbarOptions" (click)="toolbarClicked('cursor')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'cursorAnnotation' | translate}}</span>
                        <img src="/assets/img/annotations/select_2.svg" alt="" height="15" width="14">
                    </div>
                    <div class="tools-separator">
                        <img src="/assets/img/annotations/separator.svg" alt="" height="15" width="14">
                    </div>
                    <div class="annotation-color-picker" (click)="toolbarClicked('color')">
                    </div>
                    <div id="pencilIcon"
                        [ngClass]="{ 'active': activeShape==='pencil', 'disabledButton': !annotationToolbarActive}"
                        class="toolbarOptions" (click)="toolbarClicked('pencil')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'freeDrawAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/free_draw_2.svg" alt="" height="15" width="14">
                    </div>
                    <div id="circleIcon"
                        [ngClass]="{ 'active': activeShape==='circle', 'disabledButton': !annotationToolbarActive }"
                        class="toolbarOptions" (click)="toolbarClicked('circle')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'circleAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/circle_2.svg" alt="" height="15" width="14">
                    </div>
                    <div id="rectangleIcon"
                        [ngClass]="{ 'active': activeShape==='rectangle', 'disabledButton': !annotationToolbarActive }"
                        class="toolbarOptions" (click)="toolbarClicked('rectangle')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'rectangleAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/rectangle_2.svg" alt="" height="14" width="14">
                    </div>
                    <div id="lineIcon"
                        [ngClass]="{ 'active': activeShape==='arrow', 'disabledButton': !annotationToolbarActive }"
                        class="toolbarOptions" (click)="toolbarClicked('arrow')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'arrowAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/arrow_2.svg" alt="" height="15" width="14">
                    </div>
                    <div class="tools-separator">
                        <img src="/assets/img/annotations/separator.svg" alt="" height="24" width="22">
                    </div>
                    <div id="deleteIcon" class="toolbarOptions delete-icon" (click)="toolbarClicked('delete')"
                        [ngClass]="{'disabledButton': !drawingSelected}">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'deleteSelectedAnnotation' |
                            translate}}</span>
                        <img class="mb-1" src="/assets/img/annotations/delete_2.svg" alt="" height="15" width="14">
                    </div>
                    <div title="Undo" id="undoIcon" class="toolbarOptions"
                        [ngClass]="{'disabledButton': !annotatingMode && !editingMode}"
                        (click)="toolbarClicked('undo')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'undoAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/undo_2.svg" alt="" height="14" width="14">
                    </div>
                    <div title="Redo" id="redoIcon" class="toolbarOptions"
                        [ngClass]="{'disabledButton': !annotatingMode && !editingMode}"
                        (click)="toolbarClicked('redo')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'redoAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/undo_2.svg" alt="" height="14" width="14"
                            style="transform: rotateY(180deg);">
                    </div>
                    <div id="clearIcon" class="toolbarOptions"
                        [ngClass]="{'disabledButton': !annotatingMode && !editingMode}"
                        (click)="toolbarClicked('clear')">
                        <span class="tooltiptext" directiveCommentMinButtons>{{ 'clearAnnotation' | translate
                            }}</span>
                        <img src="/assets/img/annotations/clear_2.svg" alt="" height="14" width="14">
                    </div>
                    <!-- <div class="separator"></div> -->
                </div>
            </div>
            <div class="d-flex flex-row comment-container">
                <div class="annotation-nav-comment pl-1 d-flex flex-fill align-items-center">
                    <!-- <mtm-user-profile-picture [user]="authUser" [username]="authUser.username" [height]="32" [width]="32"
                        [showBorder]="false" *ngIf="pluginReady">
                    </mtm-user-profile-picture> -->

                    <div class="annotation-text-area d-flex flex-fill align-items-center" [style.position]="'relative'">
                        <div class="editor-container d-flex align-items-start flex-fill" [style.width]="'100%'">
                            <!--
                            <mtm-text-editor [elementId]="'annotCommentArea'" (keydown.enter)="$event.preventDefault()"
                                [(value)]="annotationComment" class="flex-fill" [dropUp]="false" [maxHeight]="'44px'"
                                [files]="files" [videoRecordFiles]="videoRecordFiles"
                                [audioRecordFiles]="audioRecordFiles" (keyup)="$event.stopPropagation()"
                                (enterKeyPress)="saveAnnotation()" [quillActive]="true"
                                (deleteFileCallback)="onFileDeleted($event)" [backgroundColor]="'#FFFFFF'"
                                [isAnnotationEditor]="true" (click)="$event.stopPropagation();startAnnotationMode();"
                                [userAvatar]="authUser.username" [placeholder]="getPlaceholderText()"
                                (uploadFileCallback)="uploadFileCallback($event)" [supportPrivateComment]="true"
                                [(privateCondition)]="privateCondition"
                                (privateConditionChange)="handlePrivateConditionChange($event)"
                                [showBottomToolbar]="!editingMode">
                            </mtm-text-editor>
                            -->
                            <mtm-user-profile-picture [username]="authUser?.username" [height]="28" [width]="28"
                                class="d-flex align-items-center m-2">
                            </mtm-user-profile-picture>
                            <mtm-comment-editor [cancelButtonId]="'clearModeIcon'" [elementId]="'annotCommentArea'"
                                [(value)]="annotationComment" (cancelClick)="cleanUpFiles();canceladdNewAnnotation()"
                                (submit)="saveAnnotation()" [files]="files" [videoRecordFiles]="videoRecordFiles"
                                [audioRecordFiles]="audioRecordFiles" (keyup)="$event.stopPropagation()"
                                (fileDelete)="onFileDeleted($event)" [placeholder]="getPlaceholderText()"
                                (fileUpload)="uploadFileCallback($event)" (editorClick)="startAnnotationMode();"
                                [cancelButtonEnabled]="annotatingMode || editingMode || generalCommentingMode"
                                [saveButtonEnabled]="annotatingMode || editingMode || generalCommentingMode"
                                [editButtonEnabled]="activeAnnotation && activeAnnotation.type === 'click'"
                                (editClick)="editAnnotation()">
                            </mtm-comment-editor>

                        </div>
                    </div>
                </div>
                <div class="d-none validation-buttons">
                    <div class="d-inline-flex align-items-center annot-validation">
                        <!-- <div class="separator"></div> -->
                        <div id="clearModeIcon" [ngClass]="{'disabledButton': !annotatingMode && !editingMode}"
                            class="toolbarOptions cancel-button pl-1 pr-1"
                            (click)="cleanUpFiles();canceladdNewAnnotation()">
                            <span class="tooltiptext" directiveCommentMinButtons>{{ 'cancelAnnotation' | translate
                                }}</span>
                            <img src="../../../../../assets/img/annotations/{{ annotatingMode || editingMode ? 'cancel_on' : 'cancel_off' }}.svg"
                                alt="" height="24">
                        </div>
                        <div [ngClass]="{'disabledButton': isInValidInput() === true}"
                            (click)="$event.preventDefault();saveAnnotation()" class="save-button pt-2 pb-2 pl-1 pr-1"
                            *ngIf="!isAnnotActiveUser() || editingMode ">
                            <span class="tooltiptext" directiveCommentMinButtons>{{ 'saveAnnotation' | translate
                                }}</span>
                            <img src="../../../../../assets/img/annotations/annot_send.svg" alt="" height="24">
                        </div>
                        <div id="editModeIcon" class="toolbarOptions edit-button pl-1 pr-1"
                            (click)="$event.preventDefault();editAnnotation()"
                            [ngClass]="{'disabledButton': editingMode}"
                            *ngIf="this.activeAnnotation?.type === 'click' && isAnnotActiveUser() && !editingMode">
                            <span class="tooltiptext" directiveCommentMinButtons>{{ 'editAnnotation' | translate
                                }}</span>
                            <img src="../../../../../assets/img/annotations/{{ editingMode ? 'edit_off' : 'edit_on' }}.svg"
                                alt="" height="24">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <ng-container [ngTemplateOutlet]="topMediaTemplate" *ngIf="! hideTopMediaInfo"></ng-container>
    <div class="image-outer-container col d-flex flex-column align-items-center justify-content-center
    positive-relative media-container">
        <div class="image-inner-container">
            <!-- <img #imageAsset class="image-main" id="test" [src]="item.signedURL" alt=""> -->
            <div class="vac-canvas-d" [ngClass]="{'active': annotationPlugin.isImageMovable}" #resizeDiv>
                <canvas id="canvas-fabric-image"></canvas>
            </div>
        </div>
        <ng-container [ngTemplateOutlet]="assetListTemplate"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoTemplate"></ng-container>
    </div>
    <div class="control-bar d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <div class="download-button-display d-flex align-items-center justify-content-center p-2">
                <mtm-file-download-control [file]="item" [displayType]="'icon'" #downloadButton [iconSize]="24"
                    [lightIcon]="true" [iconColor]="'white'"
                    [uniqueControlId]="item.projectId + '-' + item.sectionId + '-' + item.subSectionId + '-' + item.id"
                    [iconUrl]="'/assets/img/annotations/download.png'">
                </mtm-file-download-control>
            </div>
            <div class="toggle-annotation-display d-flex align-items-center justify-content-center"
                (click)="toggleAnnotationDisplay()">
                <img src="/assets/img/annotations/{{ isAnnotationDisplayEnabled ? 'toggleDisplayIconEnabled' : 'toggleDisplayIconDisabled' }}.png"
                    alt="" height="36">
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-end">
            <div class="move-image d-flex align-items-center justify-content-center" (click)="toggleImageMove()"
                [ngClass]="{'active': annotationPlugin.isImageMovable}">
                <img src="/assets/img/annotations/move_image.svg" alt="" height="24" width="22">
                <!-- <div></div> -->
            </div>
            <div class="image-toolbar d-inline-flex align-items-center">
                <div class="slider-container w-100">
                    <span class="zoom-decrease" (click)="setZoom(-0.1)">-</span>
                    <input title="{{imageZoom.toFixed(2)}}" type="range" min="0.1" max="3" [ngModel]="imageZoom"
                        (ngModelChange)="setImageZoom($event, true)" step="0.01" class="slider">
                    <span class="zoom-increase" (click)="setZoom(0.1)">+</span>
                </div>
            </div>
            <div class="reset-image-orientation d-inline-flex align-items-center">
                <div class="image-container" (click)="resetImageOrientation()">
                    <img src="/assets/img/annotations/resetZoom.svg" alt="" height="24" width="22">
                </div>
            </div>
        </div>
    </div>
    <div class="py-2 annotations-bar-container">
        <div #imageAnnotationMarkerContainer *ngIf="imageInitReady"
            class="annotations-bar w-100 d-flex flex-wrap positive-relative align-items-center">
            <ng-container *ngFor="let annotation of filteredAnnotations(); let i=index;">
                <div [hidden]="!isAnnotationDisplayEnabled"
                    [ngClass]="{'disabled': annotatingMode === true, 'px-1': !annotation.range.isGeneralComment}"
                    class="annotation-marker" *ngIf="!annotation.range.isGeneralComment">
                    <mtm-user-profile-picture [username]="annotation.comments[0].meta.user_id" [height]="23"
                        [width]="23" [showBorder]="false" (mouseenter)="hoverInAnnotation(annotation)"
                        (mouseleave)="hoverOutAnnotation(annotation)" (click)="annotationBarClick(annotation)">
                    </mtm-user-profile-picture>
                    <div class="annotation-comment-modal" [style.left]="getImageAnnotationCommentModalLeft(i)"
                        [ngClass]="{'annotation-toggled': activeAnnotation?.type === 'click'}"
                        *ngIf="(activeAnnotation && activeAnnotation.annotation && activeAnnotation.annotation.id===annotation.id)">
                        <div class="comment-box-container">
                            <mtm-comment #commentsComponent [projectId]="projectId" [sectionId]="sectionId"
                                [subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
                                [projectParticipants]="projectParticipants"
                                [previewAnnotation]="activeAnnotation.annotation" [annotationPlugin]="annotationPlugin"
                                [isPlayerReady]="true" [isAnnotationModeActive]="true">
                            </mtm-comment>
                        </div>
                        <div class="connector" [style.background]="userColors[activeAnnotation.username]"></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
