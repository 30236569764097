<div class="media-top-menu d-flex flex-column flex-md-row align-items-center">
  <div class="action-info d-flex align-items-center p-2">
    <i class="mtm-icon mtm-icon-topic-info mr-2 pointer" (click)="toggleAssetInfo()"></i>
    <i class="mtm-icon mtm-icon-topic-download mr-2 pointer" (click)="downloadAsset()"></i>
    <i class="mtm-icon mtm-icon-topic-list mr-2 pointer" (click)="toggleAssetList()"></i>
    <label class="m-0 d-none d-md-block file-name md-file-name"
      [title]="subSectionState?.activeFile?.displayName | fileDisplayName">{{subSectionState?.activeFile?.displayName
      | fileDisplayName}}</label>
    <div class="ml-auto d-flex d-md-none align-items-center p-2" *ngIf="!subSectionState?.reviewLink">
      <div class="d-flex pointer" (click)="share()">
        <i class="mtm-icon mtm-icon-topic-share mr-2 pointer"></i>
        <label class="m-0 _bold text-uppercase pointer">Share</label>
      </div>
    </div>
  </div>
  <div class="version-dropdown d-none d-md-block" ngbDropdown container="body"
    dropdownClass="media-player-version-dropdown">
    <button ngbDropdownToggle [disabled]="isBusy" (click)="$event.stopPropagation()"
      class="btn d-flex align-item-center justify-content-between py-2 px-3 dropdown-toggle">
      <span class="version mr-2">{{ 'V' + subSectionState?.activeVersion?.versionNumber }}</span>
      <span>
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
      </span>
    </button>
    <div ngbDropdownMenu class="p-1 dropdown-menu">
      <button *ngFor="let version of subSectionState?.activeOption?.simpleVersions"
        class="d-flex align-items-center btn dropdown-item version-selector-dropdown" [disabled]="isBusy"
        (click)="switchVersion(version.versionNumber)">
        <span class="version">{{ 'V' + version.versionNumber }}</span>
      </button>
      <button class="d-flex align-items-center justify-content-center btn dropdown-item
						  version-selector-dropdown btn-manage-versions" [disabled]="isBusy" (click)="showManageVersions()"
        *ngIf="hasEditPermission">
        <span class="text-uppercase">{{'versioning_edit_manageVersions' | translate}}</span>
      </button>
      <button class="d-flex align-items-center justify-content-center btn  dropdown-item
						   version-selector-dropdown btn-add-version" [disabled]="isBusy" (click)="createNewVersion()"
        *ngIf="hasEditPermission">
        <span class="d-flex align-items-center justify-content-center icon-wrapper mr-2">
          <i class="fa fa-plus" aria-hidden="true"></i></span>
        <span class="text-uppercase">{{'versioning_edit_createNewVersion' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="share-column d-none d-md-flex align-items-center p-2 justify-content-md-end"
    *ngIf="!subSectionState?.reviewLink">
    <div class="d-flex pointer" (click)="share()">
      <i class="mtm-icon mtm-icon-topic-share mr-2 pointer"></i>
      <label class="m-0 _bold text-uppercase pointer">Share</label>
    </div>
  </div>
  <div class="d-flex d-md-none w-100">
    <div class="mr-auto d-flex align-items-center p-2">
      <label class="m-0 file-name"
        [title]="subSectionState?.activeFile?.displayName | fileDisplayName">{{subSectionState?.activeFile?.displayName
        | fileDisplayName}}</label>
    </div>
    <div class="version-dropdown ml-auto" ngbDropdown container="body" dropdownClass="media-player-version-dropdown">
      <button ngbDropdownToggle [disabled]="isBusy" (click)="$event.stopPropagation()"
        class="btn d-flex align-item-center justify-content-between py-2 px-3 dropdown-toggle">
        <span class="version mr-2">{{ 'V' + subSectionState?.activeVersion?.versionNumber }}</span>
        <span>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </span>
      </button>
      <div ngbDropdownMenu class="p-1 dropdown-menu">
        <button *ngFor="let version of subSectionState?.activeOption?.simpleVersions"
          class="d-flex align-items-center btn dropdown-item version-selector-dropdown" [disabled]="isBusy"
          (click)="switchVersion(version.versionNumber)">
          <span class="version">{{ 'V' + version.versionNumber }}</span>
        </button>
        <button class="d-flex align-items-center justify-content-center btn dropdown-item
							  version-selector-dropdown btn-manage-versions" [disabled]="isBusy" (click)="showManageVersions()"
          *ngIf="hasEditPermission">
          <span class="text-uppercase">{{'versioning_edit_manageVersions' | translate}}</span>
        </button>
        <button class="d-flex align-items-center justify-content-center btn  dropdown-item
							   version-selector-dropdown btn-add-version" [disabled]="isBusy" (click)="createNewVersion()"
          *ngIf="hasEditPermission">
          <span class="d-flex align-items-center justify-content-center icon-wrapper mr-2">
            <i class="fa fa-plus" aria-hidden="true"></i></span>
          <span class="text-uppercase">{{'versioning_edit_createNewVersion' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
</div>