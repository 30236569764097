import * as _ from 'lodash';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output, TemplateRef,
  ViewChild
} from "@angular/core";
import {
	onCancelAddingAnnotation,
	onImageZoomChanged,
	onImagePluginReady,
	onAnnotationsLoaded,
	onAddingAnnotation,
	onUserColourCreated,
	AnnotationService
} from "app/shared/services/annotation.service";
import { AuthService } from 'app/shared/services/auth.service';
import { ImageAnnotationPlugin } from "./plugin/image-annotation.plugin";
import { TranslateService } from "app/shared/services/translate.service";
import { EmitterService } from 'app/shared/services/emitter.service';
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { wsListenerANNOTATION_CREATED } from 'app/shared/services/mtm-websocket.service';
import { commentFileAdd } from 'app/shared/services/comments.service';
import { AnnotationPrivateCondition, OptionInfo, QueryState } from "../../interfaces";
import { Subject } from 'rxjs';
import { ActiveSubsectionState, SubsectionService } from 'app/shared/services/subsection.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { OverlayService } from 'app/shared/services/overlayService';
import { SubsectionVersionListComponent } from '../subsection-version-list/subsection-version-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionUpdatedEvent } from 'app/shared/models';
import { ProjectService } from 'app/shared/services/project.service';
import { ReviewUiLinkSettingsComponent } from '../review-link/review-link-settings/review-link-settings.component';
import { MTMFileDownloadControl } from '../mtm-file-download/mtm-file-download-control/mtm-file-download-control.component';
import { SubsectionOptionEditComponent } from "../../../project-subsection/options/edit/subsection-option-edit.component";
import { Authority, PermissionService } from "../../services/permissions.service";

@Component({
	selector: 'mtm-image-annotation',
	styleUrls: ['./image-annotation.component.scss'],
	templateUrl: './image-annotation.component.html'
})
export class ImageAnnotation implements OnInit, OnDestroy {
	private subsectionService = inject(SubsectionService);
	private translatePipe = inject(TranslatePipe);
	private modalService = inject(NgbModal);
	private overlayService = inject(OverlayService);
	private projectService = inject(ProjectService);
	private permissionService = inject(PermissionService);
	@ViewChild('imageAnnotationMarkerContainer') imageAnnotationMarkerContainer: ElementRef;
	@ViewChild('downloadButton') downloadButton: MTMFileDownloadControl;
	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() projectParticipants: any[];
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() item: any;
	@Input() annotations: any[];
	@Input() authUser: any;
	@Input() hideAnnotationToolbar: boolean = true;
	@Input() hideTopMediaInfo: boolean = false;
	@Input() isSubsectionBarVisible: boolean = false;
	@Input() hasEditPermission: boolean = false;
  @Input() topMediaTemplate: TemplateRef<any>;
  @Input() assetListTemplate: TemplateRef<any>;
  @Input() assetInfoTemplate: TemplateRef<any>;
	@ViewChild('imageAsset') imageAsset: ElementRef;

	annotatingMode: boolean = false;
	editingMode: boolean = false;
	isColorOpen: boolean = false;
	activeShape: string;
	config: any;
	annotationComment: string = '';
	pluginReady: boolean = false;
	annotationPlugin: ImageAnnotationPlugin;
	imageInitReady: boolean = false;
	imageReadyWait: boolean = false;
	activeAnnotation: any; // handled inside plugin
	isAnnotationModeActive: boolean = false;
	userInfo: any;
	userColors: any = {};
	isAnnotationDisplayEnabled: boolean = false; // handled inside plugin
	lang: any;
	drawingSelected: boolean = false // handled inside plugin
	private _imageZoom: number = 0.5;
	editingAnnotation: any;
	annotationToolbarActive: boolean = true;
	wsId: string;
	newFiles: any = [];
	privateCondition: AnnotationPrivateCondition = null;
	isBusy: boolean = false;

	files: any = [];
	videoRecordFiles: any = [];
	audioRecordFiles: any = [];
	ngUnsubscribe = new Subject();
	subSectionState: ActiveSubsectionState;
	isAssetListVisible: boolean;
	optionsState: QueryState<OptionInfo>;


	// Resizing variables
	@ViewChild('resizeDiv') resizeDiv!: ElementRef<HTMLDivElement>;
	private resizeObserver!: ResizeObserver;
	resizeDebounceTime = 500 // in ms
	private onResize = _.debounce((width, height) => {
		console.log("resizing");
		const resizeData = { "width": width, "height": height, "resetViewpoint": window["res"] };
		this.annotationPlugin.fire("resize", resizeData);
	}, this.resizeDebounceTime);
	generalCommentingMode: boolean;

	constructor(
		private elementRef: ElementRef,
		private authService: AuthService,
		private stompService: MtmStompService,
		private translateService: TranslateService,
		private annotationService: AnnotationService
	) {
		window["res"] = true;
	}

	ngAfterViewInit(): void {
		this.wsId = this.stompService.subscribeToService();
		onAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationHandler(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onImageZoomChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setImageZoom(args.zoom)
		})
		onAnnotationsLoaded.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateMarker(args)
		})
		onUserColourCreated.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateBorders(args)
		})

		this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);

		// ResizeObserver Initialization
		this.resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				// Check if the current entry's target is the resizeDiv element
				if (entry.target === this.resizeDiv.nativeElement && this.annotationPlugin && this.pluginReady) {
					let width = entry.contentRect.width;
					let height = entry.contentRect.height;
					this.onResize(width, height);
				}

			}
		});

		// Observe the canvas parent element
		this.resizeObserver.observe(this.resizeDiv.nativeElement);

	}

	ngOnInit(): void {
		this.authUser = this.authService.getAuthUser();
		this.userInfo = _.find(this.projectParticipants, { username: this.authUser.username }) as any;
		this.lang = this.translateService.getLang();
		this.onImageInitReady();
		this.annotationService.privateConditionChange$.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (value: AnnotationPrivateCondition) => {
				this.privateCondition = value;
			}
		})

		this.subsectionService.subsection$
			.pipe(takeUntil(this.ngUnsubscribe),
				distinctUntilChanged((prev, curr) =>
					prev.activeOption?.optionId === curr.activeOption?.optionId && prev.activeVersion?.versionNumber === curr.activeVersion?.versionNumber))
			.subscribe({
				next: (state) => {
					this.subSectionState = state;
				}
			});

		this.subsectionService.options$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (options) => {
					this.optionsState = options;

				}
			});

		this.subsectionService.subsection$
			.pipe(takeUntil(this.ngUnsubscribe),
				distinctUntilChanged((prev, curr) => prev.activeFile === curr.activeFile))
			.subscribe({
				next: (state) => {
					this.subSectionState = state;
				}
			});


		//TODO: change with ResizeObserver
		// $(window).on('resize', () => {
		// 	console.log('try resize');
		// 	this.resetAnnotation();
		// });
	}

	reviewLinkSettings: any;
	openReviewLinkSettings() {
		this.reviewLinkSettings = this.modalService.open(ReviewUiLinkSettingsComponent, { size: 'lg', backdrop: false, modalDialogClass: 'responsive-modal' });
		let subSection = this.projectService.project.subsectionsId[this.subsectionId];
		this.reviewLinkSettings.componentInstance.setParams({
			section: subSection.projectSectionId,
			subSection: this.subsectionId,
			parentItem: this.subSectionState.activeOption,
			assets: this.optionsState.data,
			init: true
		});
	}

	toggleAssetList() {
		this.isAssetListVisible = !this.isAssetListVisible;
	}

	wsOnAnnotationCreate(args) {
		commentFileAdd.emit({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			comment: args.mediaAnnotation.comments[0],
			annotationId: args.mediaAnnotation.id,
			files: this.newFiles
		});
		this.cleanUpFiles();
	}

	checkInvalidUser() {
		let roles = this.userInfo?.roles || [];
		let isInvalidUser = false;
		const excludedRoles = ["PROJECT_STAFF", "PROJECT_SENIOR_STAFF", "PRODUCTION_STAFF", "PRODUCTION_SENIOR_STAFF"];
		const filteredRoles = roles.filter(role => !excludedRoles.includes(role));
		isInvalidUser = filteredRoles.length === 0 && this.authUser?.globalRole != "COMPANY_OCCASIONAL";
		return isInvalidUser;
	}

	onImageInitReady() {
		let _this2 = this;
		this.config = {
			userInfo: this.authUser,
			item: this.item,
			annotationObjects: this.annotations
		}
		// this.annotations = args.annotations;
		if (!this.imageInitReady) this.initPlugin();
		this.imageInitReady = true;
		// if (!this.imageReadyWait) this.imageReady();
	}

	filteredAnnotations() {
		if (this.annotationPlugin && this.annotationPlugin.annotationState) {
			let filteredAnnotations = this.annotationPlugin.annotationState.annotations.filter((a) => { if (!a.range.isGeneralComment) return a });
			return filteredAnnotations;
		}
		return [];
	}

	initPlugin() {
		this.annotationPlugin = new ImageAnnotationPlugin(this, this.config);
		// this.userInfo = this.config.userInfo;

		this.annotationPlugin.onReady(() => {
			this.pluginReady = true;
			onImagePluginReady.emit({
				act: 'onImagePluginReady',
				annotationPlugin: this.annotationPlugin
			});
			EmitterService.get('imageAnnotation.ready').emit();
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.stompService.unsubscribeToService(this.wsId);

		// Disconnect resizeObserver and debouncer
		if (this.resizeObserver) {
			this.resizeObserver.disconnect();
		}
		this.onResize.cancel();
	}

	onCancelAddingAnnotationHandler(args: any) {
		this.resetUIComponent();
		this.isAnnotationModeActive = false;
	}

	onAddingAnnotationHandler(args: any) {
		this.isAnnotationModeActive = true;
	}

	toolbarClicked(type: string) {
		if (this.annotationPlugin.isImageMovable && type !== 'none') {
			this.toggleImageMove();
		}
		if (type === 'color') {
			this.isColorOpen = false;
		} else {
			if (type !== 'undo' && type !== 'redo' && type !== 'clear') {
				this.activeShape = type;
			}
		}
		this.annotationPlugin.fire("changeActiveTool", { tool: type });
	}

	isInValidInput() {
		return !this.annotationComment && !this.newFiles.length;
	}

	isAnnotActiveUser() {
		let isValid = false;
		if (this.activeAnnotation && this.activeAnnotation.type === "click") {
			if (this.activeAnnotation.annotation.comments[0].meta.user_id === this.authUser.username) {
				isValid = true;
			}
		}
		return isValid;
	}

	editAnnotation() {
		if (this.annotationPlugin && this.pluginReady && this.activeAnnotation && this.activeAnnotation.type === "click") {
			this.annotationPlugin.fire('editAnnotation', { annotation: this.activeAnnotation.annotation });
		}
	}

	saveAnnotation() {
		if ((!this.annotationComment && !this.newFiles?.length) || this.isBusy) {
			return;
		}
		this.isBusy = true;
		if (this.newFiles.length && !this.annotationComment) {
			this.annotationComment = ' ';
		}
		if (!this.editingMode) {
			this.addNewAnnotation();
		}
		else if (!this.annotatingMode && this.editingMode) {
			this.saveEditedAnnotation();
		}
	}

	saveEditedAnnotation() {
		if (this.annotationPlugin && this.editingMode && this.pluginReady && this.editingAnnotation) {
			this.annotationPlugin.fire('saveEditedAnnotation', { annotationId: this.editingAnnotation.id });
		}
	}

	addNewAnnotation() {
		this.annotationService.updateCommentListVisibility(this.privateCondition);
		if (this.annotationPlugin && this.annotatingMode && this.pluginReady) {
			this.annotationPlugin.fire('saveAnnotation');
		}
		else if (this.annotationPlugin && this.pluginReady && !this.annotatingMode && !this.editingMode) {
			this.annotationPlugin.fire('saveGeneralComment');
			this.generalCommentingMode = false;
		}
	}

	canceladdNewAnnotation() {
		if (this.annotationPlugin && (this.annotatingMode || this.editingMode) && this.pluginReady) {
			this.annotationPlugin.fire('cancelAddingAnnotation');
		}
		else if (this.generalCommentingMode) this.resetUIComponent();
	}

	resetUIComponent() {
		this.annotationComment = '';
		this.isColorOpen = false;
		this.activeShape = '';
		this.isBusy = false;
		this.generalCommentingMode = false;
	}

	// TOGGLE ANNOTATION DISPLAY SECTION
	toggleAnnotationDisplay() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('toggleAnnotationMode');
		}
	}

	// MARKER SECTION
	updateBorders(args: any) {
		// Disabled function, use args.nameColor when enabling
		if (!(args.username in this.userColors)) {
			this.userColors[args.username] = "#FFFFFF";
		}
	}

	downloadAsset() {
		this.downloadButton.downloadFile();
	}

	resetAnnotation() {
		this.annotationPlugin.teardown();

		this.config = {
			userInfo: this.authUser,
			item: this.item,
			annotationObjects: this.annotations
		}
		this.initPlugin();
		this.isAnnotationDisplayEnabled = true;
	}

	updateMarker(args: any) {
		if (args.itemId == this.item.id) {
			this.annotations = args.data;
			this.annotationPlugin.teardown();

			this.config = {
				userInfo: this.authUser,
				item: this.item,
				annotationObjects: this.annotations
			}
			this.initPlugin();
			this.isAnnotationDisplayEnabled = true;
		}
		if (_.get(this.activeAnnotation, 'annotation')) {
			this.activeAnnotation.annotation = _.find(args.data, { id: _.get(this.activeAnnotation, 'annotation.id') });
			if (args.previewAnnotation && this.activeAnnotation.annotation) {
				args.previewAnnotation = this.activeAnnotation.annotation;
			}
		}
	}

	hoverInAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverInAnnotation', { annotation: annotation });
		}
	}

	hoverOutAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverOutAnnotation', { annotation: annotation });
		}
	}

	annotationBarClick(annotation) {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('clickInAnnotation', { annotation: annotation });
		}
	}


	// IMAGE MOVE/ZOOM SECTION
	get imageZoom() {
		return this._imageZoom;
	}

	set imageZoom(zoom) {
		if (this.annotationPlugin && this.pluginReady) {
			this._imageZoom = zoom;
		}
	}

	setImageZoom(zoom, fromAng = false) {
		this.imageZoom = zoom;
		if (fromAng) {
			this.annotationPlugin.fire('setZoom', { zoom: this.imageZoom });
		}
	}

	setZoom(change) {
		let zoom = this.imageZoom + change;
		if (zoom < 0.1) {
			return;
		}
		this.setImageZoom(zoom, true)
	}

	resetImageOrientation() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('resetImageOrientation');
		}
	}

	toggleImageMove() {
		if (this.annotationPlugin && this.pluginReady) {
			if (this.annotatingMode) {
				this.toolbarClicked('none')
			}
			this.annotationPlugin.fire('toggleImageMove')
		}
	}

	// Markers scroll
	getImageAnnotationCommentModalLeft(index) {
		let beforeElementOffset = 36;
		let scrollPosition = this.imageAnnotationMarkerContainer.nativeElement.pageXOffset || this.imageAnnotationMarkerContainer.nativeElement.scrollLeft;
		return (((index * 23) + (index * 8))) + 'px';
	}

	startAnnotationMode() {
		const canAnnotate = !this.annotatingMode && this.annotationPlugin && this.pluginReady && !this.editingMode;

		if (canAnnotate && this.annotationToolbarActive) {
			this.annotationPlugin.fire('addingAnnotation');
		}
		else if (canAnnotate && !this.annotationToolbarActive) {
			this.generalCommentingMode = true;
		}
	}

	/** Gets the comment input place holder text depending on general or annot comment */
	getPlaceholderText() {
		let placeholderText;
		if (this.annotationPlugin && this.annotationPlugin.controls && (this.annotationPlugin.controls.uiState.adding || this.annotationPlugin.controls.uiState.editing || this.annotationToolbarActive)) {
			placeholderText = "Write an annotation comment..."
		}
		else {
			placeholderText = "Write a general comment..."
		}

		return placeholderText;
	}

	/** Toggle annotation toolbar display on/off using toggle */
	toggleAnnotationToolbarDisplay(annotationToolbarActive) {
		if (!this.annotationPlugin) {
			return;
		}
		if (annotationToolbarActive == true) {
			this.annotationPlugin.fire("togglePiklorColors", true); // Changes colors in palette to depict greyed out

		}
		else {
			this.canceladdNewAnnotation(); // Triggers canceladdnew event on the plugin
			this.annotationPlugin.fire("togglePiklorColors", false);
		}
	}

	cleanUpFiles() {
		this.newFiles = [];
		this.files = [];
		this.videoRecordFiles = [];
		this.audioRecordFiles = [];
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		files.forEach(file => {
			if (file.method == 'audioRecord') {
				this.audioRecordFiles.push(file);
			} else if (file.method == 'videoRecord') {
				this.videoRecordFiles.push(file);
			} else {
				this.files.push(file);
			}
		});
		this.newFiles.push(...files);
	}

	onFileDeleted(file) {
		this.newFiles = this.newFiles.filter(f => f.lastModified != file.lastModified);
		this.audioRecordFiles = this.newFiles.filter(f => f.method == 'audioRecord');
		this.videoRecordFiles = this.newFiles.filter(f => f.method == 'videoRecord');
		this.files = this.newFiles.filter(f => !f.method);
	}

	handlePrivateConditionChange(value: AnnotationPrivateCondition) {
		this.annotationService.updatePrivateCondition(value);
	}
}
