import * as moment from 'moment-timezone'; // Import moment-timezone for timezone support
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "app/shared/services/translate.service";

@Pipe({
  name: 'commentDateFull',
  pure: false
})
export class CommentDateFullPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(value: number, timezone?: string): string {
    let lang = this.translateService.getLang();

    // Convert "zh" to "zh-cn" for moment compatibility
    if (lang === 'zh') {
      lang = 'zh-cn';
    }

    // Set the locale for moment
    moment.locale(lang as string);

    // Check if the timestamp is in milliseconds and convert to seconds
    const timestampInSeconds = value > 9999999999 ? value / 1000 : value;

    let date = moment.unix(timestampInSeconds);

    // Apply timezone if provided else system local
    if (timezone) {
      date = date.tz(timezone);
    }

    // LLL general format time which works for all languages
    return date.format('LLL');
  }
}