<div class="quill-editor-container" [class.private]="commentVisibility != null" #container>

  <div class="mtm-quill-editor" [class.toolbar-hidden]="toolbarHidden">
    <quill-editor [id]="elementId" class="d-block w-100 flex-column" [(ngModel)]="value" [modules]="quillModule"
      [placeholder]="placeholder" (onEditorCreated)="onEditorInitialized($event)"
      (click)="$event.stopPropagation();triggerEditorClick()">
    </quill-editor>
  </div>


  <div class="custom-toolbar" [class.reduced-toolbar]="!toolbarHidden">
    <ng-container *ngIf="filesToolbarVisible">
      <button class="btn btn-attach">
        <svg width="16" height="17" (click)="openFileExplorer()" [title]="'textEditorAddAttachment' | translate">
          <use xlink:href="/assets/svg/subsection/attach-file.svg#icon"></use>
        </svg>
          
        <!-- <img src="/assets/svg/subsection/attach-black.svg" alt="attach" (click)="openFileExplorer()" [title]="'textEditorAddAttachment' | translate" /> -->
        <div [title]="files.length + ' file(s)'" *ngIf="files?.length" class="file-display-thumbnail">
          <div ngbDropdown [autoClose]="true" class="file-dropdown-component d-flex align-items-center"
            container="body">
            <button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
              <span class="text-uppercase">
                {{files?.length}}
              </span>
            </button>
            <div ngbDropdownMenu class="file-item-list">
              <div class="d-flex flex-column">
                <div class="d-flex text-center pointer file-content m-2" *ngFor="let file of files">
                  <hr-badgebtn-subcmp [file]="file" [isDisplayTrashBtn]="true" [localFile]="true"
                    (onTrashFile)="deleteFile($event, file)"></hr-badgebtn-subcmp>
                  <hr-grid-subcmp [file]="file" [compactView]="true" [hideDeleteButton]="true"
                    class="w-100"></hr-grid-subcmp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>

      <button class="btn file-icon-wrapper">
        <i class="fa fa-video-camera" [title]="'textEditorRecordVideo' | translate" (click)="openCameraPreviewPopup($event)"></i>
        <div [title]="videoRecordFiles.length + ' video(s) record'" *ngIf="videoRecordFiles?.length"
          class="file-display-thumbnail">
          <div ngbDropdown [autoClose]="true" class="video-file-dropdown-component d-flex align-items-center"
            container="body">
            <button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
              <span class="text-uppercase">
                {{videoRecordFiles?.length}}
              </span>
            </button>
            <div ngbDropdownMenu class="file-item-list">
              <div class="d-flex flex-column">
                <div class="d-flex text-center pointer file-content m-2" *ngFor="let video of videoRecordFiles">
                  <hr-badgebtn-subcmp [file]="video" [isDisplayTrashBtn]="true" [localFile]="true"
                    (onTrashFile)="deleteFile($event, video)"></hr-badgebtn-subcmp>
                  <hr-grid-subcmp [file]="video" [compactView]="true" [hideDeleteButton]="true"
                    class="w-100"></hr-grid-subcmp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>

      <button class="btn file-icon-wrapper">
        <i class="fa fa-microphone" [title]="'textEditorRecordAudio' | translate" (click)="openAudioPopup($event)"></i>
        <div [title]="audioRecordFiles.length + ' audio(s) record'" *ngIf="audioRecordFiles?.length"
          class="file-display-thumbnail">
          <div ngbDropdown [autoClose]="true" class="audio-file-dropdown-component d-flex align-items-center"
            container="body">
            <button type="button" class="d-flex align-items-center btn" ngbDropdownToggle>
              <span class="text-uppercase">
                {{audioRecordFiles?.length}}
              </span>
            </button>
            <div ngbDropdownMenu class="file-item-list">
              <div class="d-flex flex-column">
                <div class="d-flex text-center pointer file-content m-2" *ngFor="let audio of audioRecordFiles">
                  <hr-badgebtn-subcmp [file]="audio" [isDisplayTrashBtn]="true" [localFile]="true"
                    (onTrashFile)="deleteFile($event, audio)"></hr-badgebtn-subcmp>
                  <hr-grid-subcmp [file]="audio" [compactView]="true" [hideDeleteButton]="true"
                    class="w-100"></hr-grid-subcmp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </button>
    </ng-container>

    <button class="btn btn-emote" (click)="toggleEmojiPicker()" [title]="'textEditorInsertEmoji' | translate">
      <i class="fa fa-smile-o" aria-hidden="true"></i>
    </button>

    <button class="btn btn-style" (click)="toggleToolbar()" [title]="'textEditorFormatting' | translate"
      [ngClass]="{'active': !toolbarHidden}"
    >
      <!-- <img src="/assets/svg/subsection/style-formatting.svg" alt="style"> -->
      <svg width="20" height="13" *ngIf="toolbarHidden">
        <use xlink:href="/assets/svg/subsection/style-formatting.svg#icon"></use>
      </svg>
      <svg width="20" height="13" *ngIf="!toolbarHidden">
        <use xlink:href="/assets/svg/subsection/style-formatting-active.svg#icon"></use>
      </svg>
    </button>

    <button [id]="cancelButtonId" class="btn btn-cancel" (click)="cancel()" 
      [ngClass]="{'disabled': !saveButtonEnabled || !value}"[disabled]="!cancelButtonEnabled">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>

    <button class="btn btn-edit" *ngIf="editButtonEnabled" (click)="edit()">
      <img src="/assets/img/annotations/edit_on.svg" alt="send" />
    </button>

    <div class="d-flex justify-content-center align-items-center submit-container" *ngIf="! editButtonEnabled">
      <div class="btn-send-container px-1 d-flex justify-content-center align-items-center h-100" 
        [ngClass]="{'visibility-selector-enabled': visibilitySelectorEnabled, 'disabled': !saveButtonEnabled || !value}">
        <button class="btn btn-send" (click)="send()"[ngClass]="{'disabled': !saveButtonEnabled || !value}"
          [disabled]="!saveButtonEnabled || !value">
          <svg width="18" height="16">
            <use xlink:href="/assets/svg/subsection/send.svg#icon"></use>
          </svg>
          <!-- <img src="/assets/svg/subsection/send.svg" alt="send" /> -->
        </button>
      </div>
      <div ngbDropdown class="visibility-selector d-flex justify-content-betwee align-items-center h-100" 
        container="body" *ngIf="visibilitySelectorEnabled" [ngClass]="{'disabled': !saveButtonEnabled || !value}">
        <span class="separator mr-auto"></span>
        <div class="d-flex align-items-center selector-inner mx-auto">
          <button class="btn btn-visibility-toggle"[ngClass]="{'disabled': !saveButtonEnabled || !value}"
            ngbDropdownToggle [disabled]="!saveButtonEnabled || !value">
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </button>
        </div>
        <div ngbDropdownMenu class="comment-editor-dropdown-menu" aria-labelledby="visibilitySelector">
          <button class="btn dropdown-item" (click)="setVisibility(null)">
            <span>Public</span>
          </button>
          <button class="btn dropdown-item" (click)="setVisibility('DOMAIN')">
            <span>Private</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none">
    <mtm-upload-file [isRoundedCircle]=false [isRedirectImageLoadError]=false (files)="handleFileUpload($event)"
      #mtmUpload>
    </mtm-upload-file>
  </div>
</div>