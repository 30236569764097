<div class="video-recorder-modal p-4">
    <i class="fa fa-times pointer" (click)="closeModal()"></i>
    <label class="title">{{'recordClip'|translate}}</label>
    <div class="text-center mb-2">
        <span class="error" *ngIf="!allowed">Please allow access to your video.</span>
        <video #videoElement class="w-100">
            <source *ngIf="!isVideoRecording && videoBlobUrl" [src]="videoBlobUrl">
        </video>
    </div>
    <div class="d-flex">
        <div class="d-flex align-items-center _600" [style.width]="'48pxpx'">
            <span [style.width]="'32px'" [style.text-align]="'right'">{{(minutes < 10 ? '0' : '' ) + minutes}}</span>
                    <span [style.padding]="'0 2px'">:</span>
                    <span [style.width]="'32px'" [style.text-align]="'left'">{{(seconds < 10 ? '0' : '' ) +
                            seconds}}</span>
        </div>
        <div class="d-flex ml-auto">
            <button [disabled]="!allowed" class="btn mtm-button mtm-black-button pointer text-center c-full-white _600 pt-2 pb-2 start-button"
                *ngIf="!isVideoRecording && !videoBlobUrl" (click)="startVideoRecording()">Record</button>
            <button [disabled]="!allowed"
                class="btn mtm-button mtm-grey-button pointer text-center c-mtm-black _600 pt-2 pb-2 stop-button mr-2"
                *ngIf="isVideoRecording && !videoBlobUrl"
                (click)="isRecordPause ? resumeVideoRecording() : pauseVideoRecording()">
                {{ isRecordPause ? 'Resume' : 'Pause' }}
            </button>
            <button [disabled]="!allowed"
                class="btn mtm-button mtm-black-button pointer text-center c-full-white pt-2 _600 pb-2 ml-2 stop-button"
                *ngIf="isVideoRecording && !videoBlobUrl" (click)="stopVideoRecording()">Stop</button>
        </div>
        <button [disabled]="!allowed" class="btn mtm-button mtm-grey-button c-mtm-black mr-2 _600" *ngIf="!isVideoRecording && videoBlobUrl"
            (click)="clearVideoRecordedData()">Cancel</button>
        <button [disabled]="!allowed" class="btn mtm-button mtm-black-button c-full-white _600" *ngIf="!isVideoRecording && videoBlobUrl"
            (click)="downloadVideoRecordedData()">Done</button>
    </div>
</div>