import * as _ from 'lodash';
import { Component, DoCheck, Input, OnDestroy } from "@angular/core";
import { UserService } from "app/shared/services/user.service";
import { UUID } from "angular2-uuid";
import { onUserColourCreated } from 'app/shared/services/annotation.service';
import { EmitterService } from 'app/shared/services/emitter.service';
import { AuthService } from 'app/shared/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'app/shared/services/helper.service';
import { ProjectService } from 'app/shared/services/project.service';
import { TimelineService } from 'app/shared/services/timeline.service';

@Component({
	selector: 'mtm-user-profile-picture',
	templateUrl: './mtm-user-profile-picture.component.html',
	styleUrls: ['./mtm-user-profile-picture.component.scss']
})
export class UserProfilePicture implements OnDestroy, DoCheck {
	private _avatarUrl: string = '';

	@Input() username: any;
	@Input() user: any;

	get avatarUrl(): string {
		return this._avatarUrl;
	}
	@Input()
	set avatarUrl(value: string) {
		if (!value.includes('avatar-no-image.png')) {
			this._avatarUrl = value;
		}
	}
	@Input() height: any = 32;
	@Input() width: any = 32;
	@Input() squareIcon: boolean = false;
	@Input() fontSize: any = '12px';
	@Input() disabled: boolean = false;
	@Input() showBorder: boolean = false;
	@Input() forceLoadProfile: boolean = false;


	usersList: any = {};
	currentUser: any;
	initialName: any;
	nameColor: any;
	blobPicture: any;
	usersLoaded: boolean = false;
	authUser: any;
	ngUnsubscribe = new Subject();
	avatarLoaded: boolean = false;

	constructor(
		public userService: UserService,
		private projectService: ProjectService,
		private authService: AuthService,
		public timelineService: TimelineService
	) {
		this.nameColor = this.stringToHslColor(UUID.UUID() + " " + UUID.UUID());

		// Subscribe to change avatar user
		EmitterService.get('authUser.changeAvatar').pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe((data) => {
			if (data && data.externalUser) {
				this.username = data.user.email;
				setTimeout(() => {
					this.reloadProfilePicture();
				}, 3500);
			} else if (data && data.email === this.username) {
				setTimeout(() => {
					this.reloadProfilePicture();
				}, 3500);
			}
		})
	}
	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
	}

	ngOnInit(): void {
		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.
		this.authUser = this.authService.getAuthUser();
		this.nameColor = this.stringToHslColor(this.username);
	}


	//TODO: subject for improvement
	async ngDoCheck() {
		if (!this.username && (!this.projectService.projectOnloaded || !this.projectService.usersLoaded)) {
			return;
		} else if (this.username || this.user) {
			if (this.user) {
				if (!this.avatarUrl && this.user.avatarUrl) {
					this.avatarUrl = this.user.avatarUrl;
				}
				this.setProfilePicture(this.user);
			} else {
				this.user = this.userService.users.find(u => u.username == this.username);
				if (this.user) {
					this.setProfilePicture(this.user);
				}
			}
			if (!this.initialName) {
				if (this.user) {
					this.setProfilePicture(this.user);
				} else {
					this.initialName = this.username.substring(0, 2).toLowerCase();
					this.nameColor = this.stringToHslColor(this.initialName);
				}
			}
		}

		if (this.initialName && (this.user && !this.usersLoaded)) {
			this.usersLoaded = true;
			return;
		}
		let isProjectParticipants = _.filter(
			_.get(this.projectService, 'project.participants', []),
			(participant) => {
				return participant.email === this.username;
			}
		).length;
		if (!isProjectParticipants && !this.forceLoadProfile) {
			this.usersLoaded = true;
			return;
		}
		this.usersList = this.userService.usersList || [];
		this.currentUser = this.usersList[this.username];
		if (this.currentUser) {
			this.setProfilePicture(this.currentUser);
		} else if (!this.usersLoaded) {
			this.usersLoaded = true;
			this.user = _.find(this.userService.users, (user) => user.username === this.username);
			if ((this.authUser && this.authUser.avatarUrl) || this.forceLoadProfile) {
				this.userService.getProfileBlob(this.authUser.username, this.authService.getAuthSession(), this.authUser.companyId);
			}
		}
		let nameColor = ((this.blobPicture) ? this.nameColor : "rgb(169, 169, 169)");
		onUserColourCreated.emit({
			act: 'onUserColourCreated',
			username: this.username,
			nameColor: nameColor
		});
	}

	private setProfilePicture(currentUser) {
		let fullName = currentUser.fullName ? currentUser.fullName : (currentUser.firstName || '') + ' ' + (currentUser.lastName || '');
		if (!this.initialName) {
			this.initialName = this.getInitials(fullName);
			this.nameColor = this.stringToHslColor(this.initialName);
		}

		this.blobPicture = _.get(currentUser, 'blobPicture') ? void 0 : currentUser.blobPicture;

	}

	reloadProfilePicture() {
		this.usersLoaded = false;
		this.usersList[this.username] = void 0;
		this.currentUser = void 0;
	}

	stringToHslColor(initial: any = []) {
		let hash = 0;
		if (!initial) {
			return;
		}
		for (let i = 0; i < initial.length; i++) {
			hash = initial.charCodeAt(i) + ((hash << 5) - hash);
		}
		let h = hash % 360;
		return 'hsl(' + h + ', 75%, 62%)';
	}

	isHasProfilePicture() {
		return this.usersList && this.username && this.usersList[this.username] && this.usersList[this.username].blobPicture;
	}

	getInitials(fullName: string): string {
		let fullNameTrimmed = fullName.trim();
		if (!fullNameTrimmed) {
			return '';
		}
		try {
			return fullNameTrimmed.trim().split(' ').filter(c => !!c.trim()).slice(0, 2).map(part => part[0]).join('').toLowerCase();
		} catch (ex) {
			console.log('failed to trim username', fullNameTrimmed, 'because of:', ex);
			return fullName.slice(0, 2).toLowerCase();
		}
	}

	onAvatarLoaded() {
		this.avatarLoaded = true;

	}
}
