<div class="media-player-container d-flex flex-column">

  <!-- <mtm-pdf-transcode-error></mtm-pdf-transcode-error> -->
  <div *ngIf="item?.status==='uploading'" class="mtm-file-download-upload-preview d-flex flex-column m-auto"
       [style.max-width]="'540px'" [style.width]="'100%'">
    <div class="d-flex align-items-center p-2 m-auto _600 w-100" [style-border-bottom]="'1px solid #ccc'">
      <span class="d-inlone-block m-auto">{{ 'uploadInProgress' | translate }}</span>
    </div>
    <div class="d-flex p-3" *ngFor="let file of item.data">
      <div class="mtm-file-download-upload-preview-icon">
        <img type="image/svg+xml" [src]="getFileTypeIcon(file)" onError="this.src='assets/img/icons/file.svg'">
      </div>
      <div class="mtm-file-download-upload-preview-meta flex-fill d-flex flex-column">
        <div class="d-flex align-items-center pt-1">
          <div class="filename mr-auto flex-fill pt-1 pb-1" fileNameDisplay [fileName]="file?.displayName">
          </div>
          <div class="progress-in-text ml-auto pt-1 pb-1">{{ file?.progress }}%</div>
        </div>
        <div class="mtm-file-download-upload-preview-progress mt-2">
          <div class="progress-background" [style.width]="(file.progress || 0) + '%'"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-block h-100" *ngIf="item?.status!=='uploading'">
    <div *ngIf="showCloseButton" class="file-close-btn d-flex justify-content-between">
      <span class="text-white f-12 p-1">{{ item?.displayName || '' }}</span>
      <button (click)="activeModal.close()" class="btn btn-sm btn-danger f-12"> Close</button>
    </div>
    <!-- Video -->
    <div *ngIf="(mediaType === 'video') && enableVideoAnnotation && !isError" #videoTools id="videoTools"
         [hidden]="hideAnnotationToolbar || !canAddAnnotationComment" class="image-drawing-container mb-2">
      <mtm-image-drawing-tools [projectId]="projectId" [sectionId]="sectionId" [subsectionId]="subsectionId"
                               [typeId]="typeId" [item]="item" [isExpandByDefault]="true"
                               [projectParticipants]="projectParticipants"
                               [annotationPlugin]="annotationPlugin"></mtm-image-drawing-tools>
    </div>

    <div *ngIf="mediaType === 'video' && !isError" class="media-player annotation-content-preview" [style.width]="width"
         #videoContainer id="videoContainer">
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="position-relative video-player"
           [ngClass]="{ 'min-h-250': !customSize, 'annotation-active': enableVideoAnnotation, 'drawing-hidden': !canAddAnnotationComment }">
        <video id="videoJsEl" class="video-js m-0" controls #videoPlayer></video>
        <div *ngIf="previewSubtitle" class="vjs-text-track-display">
          <div style="position: absolute; inset: 0px; margin: 1.5%;">
            <div class="vjs-text-track-cue vjs-text-track-cue-en" lang="en"
                 style="direction: ltr; writing-mode: horizontal-tb; unicode-bidi: plaintext; text-align: center; font: 17.32px sans-serif; white-space: pre-line; position: absolute; width: 620.8px; inset: 325.4px 0px 0px; height: 21px; background-color: rgba(0, 0, 0, 0);">
              <div
                style="color: rgb(255, 255, 255); background-color: rgb(0, 0, 0); position: relative; inset: 0px; display: inline; writing-mode: horizontal-tb; unicode-bidi: plaintext; font-family: sans-serif;">
                The quick brown fox jumps over the lazy dog
              </div>
            </div>
          </div>
        </div>
        <div class="d-block annotation-bar-container mt-3" *ngIf="enableVideoAnnotation && !hideAnnotationToolbar">
          <mtm-videojs-annotation-bar-list [annotations]="annotations" [player]="player" [projectId]="projectId"
                                           [sectionId]="sectionId" [subsectionId]="subsectionId"
                                           [projectParticipants]="projectParticipants"
                                           [typeId]="typeId" [item]="item" [annotationPlugin]="annotationPlugin"
                                           [isPlayerReady]="isPlayerReady"
                                           [isVideo]="true">
          </mtm-videojs-annotation-bar-list>
        </div>
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
      </div>
    </div>
    <!--Video Error-->
    <div *ngIf="mediaType === 'video' && isError"
         class="loading-container h-100 d-flex justify-content-center min-h-250" id="loadingVideoContainer"
         #loadingVideoContainer>
      <div class="loading-box d-flex align-items-center justify-content-center flex-column">
        <div class="loading-icon">
          <div class="spinner"></div>
          <img alt="" height="20" width="28" src="../../../../../assets/img/icons/download/process_red.png">
        </div>
        <span class="heading-text">The media could not be loaded……</span>
      </div>
    </div>
    <div *ngIf="mediaType === 'video'" class="loading-container h-100 d-flex hidden-container justify-content-center"
         id="loadingVideoContainer" #loadingVideoContainer [ngClass]="{'min-h-250': !portfolioPreview}">
      <div *ngIf="enableVideoAnnotation"
           class="loading-box d-flex align-items-center justify-content-center flex-column">
        <div class="loading-icon">
          <div class="spinner"></div>
          <img alt="" height="20" width="28" src="../../../../../assets/img/icons/download/process_red.png">
        </div>
        <span class="heading-text">Please be patient while we load and transcode your video</span>
      </div>
      <div *ngIf="!enableVideoAnnotation && !portfolioPreview"
           class="preview-loading-box d-flex align-items-center justify-content-center flex-column">
        <div class="loading-icon">
          <div class="spinner"></div>
          <img alt="" height="20" width="28" src="../../../../../assets/img/icons/download/process_red.png">
        </div>
        <span class="heading-text">Please be patient while we load and transcode your video</span>
      </div>
      <div *ngIf="portfolioPreview && !enableVideoAnnotation"
           class="portfolio-loading-box h-100 w-100 d-flex justify-content-center align-items-center"
           [style.min-height]="previewContainerHeight">
        <div class="spinner"></div>
        <img alt="" height="20" width="28" src="../../../../../assets/img/icons/download/process_red.png">
      </div>
    </div>
    <!-- AUDIO ANNOTATION -->
    <div *ngIf="mediaType=='audio' && enableAudioAnnotation"
         [ngClass]="{'w-100 audio-parent-container-forComment': ngClassState.valueOf()==true,'col w-100  h-100 audio-container':ngClassState.valueOf()==false}">
      <mtm-audio-annotation class="w-100 h-100" [projectId]="projectId" [sectionId]="sectionId"
                            [subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
                            [hideAnnotationToolbar]="hideAnnotationToolbar"
                            [projectParticipants]="projectParticipants" [annotations]="annotations"
                            [authUser]="userInfo"
                            [hideTopMediaInfo]="hideTopMediaInfo" [hasEditPermission]="hasEditPermission"
                            [assetListTemplate]="assetListContainer" [assetInfoTemplate]="assetInfoContainer"
                            [topMediaInfoTemplate]="topMediaContainer">
      </mtm-audio-annotation>
    </div>
    <!-- AUDIO -->
    <div *ngIf="mediaType=='audio' && !enableAudioAnnotation" class="audio-container-wrapper">
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="position-relative asset-container">
        <div class="col w-100 h-100 audio-container"
             [ngClass]="{'min-h-250': !portfolioPreview, 'portfolio-preview': portfolioPreview, 'reorder-preview':reorderPreview}">
          <!-- <img [ngClass]="{'min-h-250 min-h-470': ngClassState.valueOf()==true,'w-100 h-100 image min-h-250 ':ngClassState.valueOf()==false}"
          src="../../../../../assets/img/thumpnails/audio-file.png"> -->
          <div *ngIf="uuid" class="wave-form-container" id="{{uuid}}"></div>
          <audio class="w-100 audio" controls #mtmAudioPlayer [preload]="audioPreloadMethod">
            <source>
          </audio>
        </div>
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
      </div>
    </div>
    <!-- PDF -->
    <div *ngIf="mediaType=='pdf' && file" class="w-100 h-100 min-h-250 d-flex flex-column">
      <mtm-comment-annotation class="" [annotations]="annotations" [item]="item" [projectId]="projectId"
                              [sectionId]="sectionId" [subsectionId]="subsectionId"
                              [hideAnnotationToolbar]="hideAnnotationToolbar"
                              *ngIf="enableGeneralComments && !hideTopMediaInfo"
                              [hideTopMediaInfo]="hideTopMediaInfo"></mtm-comment-annotation>
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="position-relative document-container">
        <iframe [src]="file.previewSRC | safe " class="w-100 min-h-250 h-100" frameborder="0"></iframe>
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
      </div>
      <!-- <mtm-pdf-annotation class="w-100 h-100" [item]="item"></mtm-pdf-annotation> -->
    </div>
    <!--  IMAGE ANNOTATION   -->
    <div class="d-flex align-items-center justify-content-center media-container"
         *ngIf="mediaType == 'image' && enableImageAnnotation">
      <mtm-image-annotation class="w-100 h-100" [projectId]="projectId" [sectionId]="sectionId"
                            [subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
                            [projectParticipants]="projectParticipants"
                            [hideAnnotationToolbar]="hideAnnotationToolbar" [annotations]="annotations"
                            [authUser]="userInfo"
                            [hideTopMediaInfo]="hideTopMediaInfo" [isSubsectionBarVisible]="isSubsectionBarVisible"
                            [hasEditPermission]="hasEditPermission" [topMediaTemplate]="topMediaContainer"
                            [assetListTemplate]="assetListContainer" [assetInfoTemplate]="assetInfoContainer">
      </mtm-image-annotation>
    </div>
    <!-- Image -->
    <div class="image-non-annotation d-flex flex-column" *ngIf="mediaType == 'image' && !enableImageAnnotation">
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="position-relative asset-container">
        <div class="card-img card-background"
             [ngClass]="{'card-img-view': mode == 'view', '11rem': fillContainer, 'img-cover': isSideImg, 'min-h-250  h-100': !portfolioPreview, 'portfolio-preview': portfolioPreview}"
             [style.background-image]="imgSrc" (click)="showImageInNewTab()">
        </div>
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
      </div>
    </div>

    <!-- URL -->
    <div class="card-img w-100 h-100 min-h-250" *ngIf="mediaType == 'url'">
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="position-relative asset-container">
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
        <embed-video [fix]="fix" #youtube [height]="'100%'" [width]="'100%'" [link]="item">
        </embed-video>
      </div>
    </div>
    <!-- Subtitle -->
    <div class="w-100 h-100 min-h-250" *ngIf="mediaType == 'subtitle'">
      <mtm-comment-annotation class="" [annotations]="annotations" [item]="item" [projectId]="projectId"
                              [sectionId]="sectionId" [subsectionId]="subsectionId"
                              [hideAnnotationToolbar]="hideAnnotationToolbar"
                              [hideTopMediaInfo]="hideTopMediaInfo"></mtm-comment-annotation>
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <div class="card-img w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center">
        <img class="mb-2" alt="" height="120" width="120" src="../../../../../assets/img/srtIco.svg">
        <div class="d-flex flex-column align-items-center">
          <span>{{ file.displayName }}</span>
          <span>{{ formatSizeUnits(file.size) }}</span>
          <span>{{ file.addedTime | date:'M/d/yy h:mm a' }}</span>
        </div>
        <div *ngIf="showDownloadButtonIfNotPreviewAble" class="download-button-control mt-2">
          <mtm-file-download-control [file]="file" [displayType]="'icon'" [iconSize]="24"
                                     [uniqueControlId]="'media-player-download-not-exists' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
          </mtm-file-download-control>
        </div>
      </div>
    </div>
    <div class="download-link  not-supported-container w-100 h-100 min-h-250" *ngIf="isFileNotSupported">
      <mtm-comment-annotation class="" [annotations]="annotations" [item]="item" [projectId]="projectId"
                              [sectionId]="sectionId" [subsectionId]="subsectionId"
                              *ngIf="enableGeneralComments && !hideTopMediaInfo"
                              [hideAnnotationToolbar]="hideAnnotationToolbar"
                              [hideTopMediaInfo]="hideTopMediaInfo"></mtm-comment-annotation>
      <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
      <!--
      <span class="not-previewable-message" [style.text-align]="'center'"
        [innerHtml]="'fileNotPreviewAble' | translate"></span>
      <div class="w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center">
        <div *ngIf="showDownloadButtonIfNotPreviewAble" class="download-button-control mt-2">
          <mtm-file-download-control [file]="file" [displayType]="'icon'" [iconSize]="24"
            [uniqueControlId]="'media-player-download-not-exists' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
          </mtm-file-download-control>
        </div>
      </div>
      -->
      <div
        class="content-container position-relative asset-container w-100 h-100 d-flex flex-column align-items-center justify-content-center py-4 py-xl-0">
        <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
        <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>
        <img class="d-block mx-auto mb-3" src="/assets/img/versioning/unknown-file.svg" alt="unknown file" width="36"
             height="46">
        <div class="not-supported-title text-uppercase mb-3"
             [innerHTML]="'mediaPlayer_notSupported_title' | translate: displayInfo"></div>
        <div class="not-supported-desc text-center mb-3">
          <span class="d-block">{{ 'mediaPlayer_notSupported_desc1' | translate }}</span>
          <span class="d-block mb-3">{{ 'mediaPlayer_notSupported_desc2' | translate }}</span>
          <span class="d-block">{{ 'mediaPlayer_notSupported_desc3' | translate }}</span>
        </div>
        <!--
        <button class="btn btn-download d-flex align-items-center" *ngIf="enableGeneralComments">
          <img class="mr-2" src="/assets/img/versioning/unknown-file-download.svg" alt="Download" width="22" height="22" />
          <span class="text-uppercase">{{'mediaPlayer_downloadFile' | translate }}</span>
        </button>
        -->

        <mtm-file-download-control [file]="file" [displayType]="'unknown-download'"
                                   *ngIf="showDownloadButtonIfNotPreviewAble"
                                   [uniqueControlId]="'media-player-download-not-exists' + file.projectId + '-' + file.sectionId + '-' + file.subSectionId + '-' + file.id">
        </mtm-file-download-control>

      </div>
    </div>
    <div class="w-100 h-100 min-h-250 d-flex flex-column" *ngIf="mediaType == 'officeDoc' && file">
      <div class="w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center office-loader"
           *ngIf="fileTranscodingStatus == 'in-progress' ">
        <span class="text-danger d-inline-block mb-2"><i [style.font-size]="'24px'"
                                                         class="fa fa-spinner fa-pulse"></i></span>
        <span class="heading-text">{{ 'mediaPlayer_transcoding_inProgress' | translate }}</span>
      </div>

      <div class="w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center office-failed"
           *ngIf="fileTranscodingStatus == 'failed'">
        <mtm-pdf-transcode-error [file]="file" [transcodingType]="TranscodingTypes.Pdf"
                                 (fileReplaced)="checkTranscodingReplacementFile()"></mtm-pdf-transcode-error>
      </div>

      <ng-container *ngIf="fileTranscodingStatus == 'success'">
        <mtm-comment-annotation class="" [annotations]="annotations" [item]="item" [projectId]="projectId"
                                [sectionId]="sectionId" [subsectionId]="subsectionId"
                                [hideAnnotationToolbar]="hideAnnotationToolbar"
                                [hideTopMediaInfo]="hideTopMediaInfo"
                                *ngIf="enableGeneralComments && !hideTopMediaInfo"></mtm-comment-annotation>
        <ng-container [ngTemplateOutlet]="topMediaContainer" *ngIf="! hideTopMediaInfo"></ng-container>
        <div class="position-relative document-container">
          <iframe [src]="file.previewSRC | safe " class="w-100 min-h-250 h-100 pdf-file-frame-container"
                  frameborder="0"></iframe>
          <ng-container [ngTemplateOutlet]="assetListContainer"></ng-container>
          <ng-container [ngTemplateOutlet]="assetInfoContainer"></ng-container>

        </div>
      </ng-container>
    </div>

    <div class="w-100 h-100 min-h-250" *ngIf="mediaType == 'convertibleImage' && file">
      <div class="w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center office-loader"
           *ngIf="fileTranscodingStatus == 'in-progress'">
        <span class="text-danger d-inline-block mb-2"><i [style.font-size]="'24px'"
                                                         class="fa fa-spinner fa-pulse"></i></span>
        <span class="heading-text">{{ 'mediaPlayer_transcoding_inProgress' | translate }}</span>
      </div>

      <div class="w-100 h-100 min-h-250 d-flex flex-column align-items-center justify-content-center office-failed"
           *ngIf="fileTranscodingStatus == 'failed'">
        <!--
        <span class="text-danger d-inline-block mb-2"><i [style.font-size]="'24px'"
                                                         class="fa fa-times"></i></span>
        <span class="heading-text">{{'mediaPlayer_transcoding_failed' | translate }}</span>
        -->
        <mtm-pdf-transcode-error [file]="file" [transcodingType]="TranscodingTypes.Png"
                                 (fileReplaced)="checkTranscodingReplacementFile()"></mtm-pdf-transcode-error>
      </div>
      <ng-container *ngIf="fileTranscodingStatus == 'success'">
        <mtm-image-annotation class="w-100 h-100" [projectId]="projectId" [sectionId]="sectionId"
                              [subsectionId]="subsectionId" [typeId]="typeId" [item]="item"
                              [hideAnnotationToolbar]="hideAnnotationToolbar"
                              [projectParticipants]="projectParticipants" [annotations]="annotations"
                              [authUser]="userInfo"
                              [hasEditPermission]="hasEditPermission" [topMediaTemplate]="topMediaContainer"
                              [assetListTemplate]="assetListContainer" [assetInfoTemplate]="assetInfoContainer">
        </mtm-image-annotation>
      </ng-container>
    </div>

  </div>

  <ng-template #topMediaContainer>
    <top-media-info [subSectionState]="subSectionState" [isBusy]="isBusy" [hasEditPermission]="hasEditPermission"
                    (switchVersionClick)="switchVersion($event)" (manageVersionsClick)="showManageVersions()"
                    (newVersionClick)="createNewVersion()" (downloadAssetClick)="downloadAsset()"
                    (assetListClick)="toggleAssetList()" (assetInfoClick)="toggleAssetInfo()"
                    (shareClick)="openReviewLinkSettings()"></top-media-info>
  </ng-template>

  <ng-template #assetListContainer>
    <subsection-preview-asset-list *ngIf="isAssetListVisible" [activeFile]="item"
                                   (fileClick)="handleAssetClick()"></subsection-preview-asset-list>
  </ng-template>

  <ng-template #assetInfoContainer>
    <mtm-asset-info [file]="file" [(visible)]="isAssetInfoVisible"></mtm-asset-info>
  </ng-template>
</div>
