<div class="asset-info-container">
  <div class="inner-container p-3">

    <div class="d-flex align-items-center justify-content-between mb-3 header">
      <div></div>
      <div class="title text-uppercase">Asset Information</div>
      <div class="">
        <button class="d-flex align-items-center justify-content-center btn close-btn" (click)="hideAssetInfo()">
          <i class="fa fa-close" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <div class="file-info">
      <div>
        <span class="info-label">{{ 'fileName' | translate }}</span>
        <span class="">{{ file.displayName | fileDisplayName }}</span>
      </div>
      <div>
        <span class="info-label">{{ 'fileType' | translate }}: </span>
        <span class="text-lowercase">{{ file.displayName | mtmFileExtension }}</span>
      </div>
      <div class="text-light mb-2" *ngIf="file.size">
        <span class="info-label">{{ 'fileSize' | translate }}: </span>
        <span>{{ file.size | mtmFileSize }}</span>
      </div>
      <div class="d-flex align-items-center">
        <mtm-user-profile-picture [forceLoadProfile]="true" *ngIf="file.createdBy" [username]="file.createdBy"
          [height]="36" [width]="36" class="mr-2"></mtm-user-profile-picture>
        <div class="d-flex flex-column">
          <div class="mb-2" *ngIf="participants[file.createdBy]">
            <span class="info-label text-light mr-2">{{ 'uploadedBy' | translate }}</span>
            <span class="text-light user-name m-0">{{ participants[file?.createdBy].fullName }}</span>
          </div>
          <div>
            <span class="info-label text-light mr-2" *ngIf="file.addedTime">{{ 'uploadedAt' | translate }}</span>
            <span class="text-light m-0">{{ file.addedTime | mtmDateTime }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>