import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'mtmFileExtension'
})
export class MtmFileExtensionPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      let split = value.split('.');
      if (split.length > 1) {
        return split[split.length - 1];
      }
    }
    return '';
  }
}
