import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActiveSubsectionState } from "../../../services/subsection.service";

@Component({
  selector: 'top-media-info',
  templateUrl: './top-media-info.component.html',
  styleUrls: ['./top-media-info.component.scss'],
})
export class TopMediaInfoComponent implements OnInit, OnDestroy {
  @Input() isBusy: boolean = false;
  @Input() subSectionState: ActiveSubsectionState = null;
  @Input() hasEditPermission: boolean = false;
  @Output() switchVersionClick = new EventEmitter<number>();
  @Output() manageVersionsClick = new EventEmitter<void>();
  @Output() newVersionClick = new EventEmitter<void>();
  @Output() downloadAssetClick = new EventEmitter<void>();
  @Output() assetListClick = new EventEmitter<void>();
  @Output() assetInfoClick = new EventEmitter<void>();
  @Output() shareClick = new EventEmitter<void>();

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  switchVersion(versionNumber: number) {
    this.switchVersionClick.emit(versionNumber);
  }

  showManageVersions() {
    this.manageVersionsClick.emit();
  }

  createNewVersion() {
    this.newVersionClick.emit();
  }

  downloadAsset() {
    this.downloadAssetClick.emit();
  }

  toggleAssetList() {
    this.assetListClick.emit();
  }

  toggleAssetInfo() {
    this.assetInfoClick.emit();
  }

  share() {
    this.shareClick.emit();
  }
}
