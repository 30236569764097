import {
  Component, ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import Emitter from 'quill/core/emitter';
import { ProjectService } from "../../services/project.service";
import { HelperService } from "../../services/helper.service";
import * as _ from 'lodash';
import { Subject } from "rxjs";
import { AnnotationPrivateCondition } from "../../interfaces";
import Quill from "quill";
import QuillEmoji from 'quill-emoji';
import Mention from 'quill-mention';
import { UploadFileComponent } from "../mtm-upload-file/upload-file.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  VideoRecorderModalComponent
} from "../../../sub-modules/mtm-chat-module/mtm-text-editor/video-recorder-modal/video-recorder-modal.component";
import {
  AudioRecorderModalComponent
} from "../../../sub-modules/mtm-chat-module/mtm-text-editor/audio-recorder-modal/audio-recorder-modal.component";
import { EmitterService } from "../../services/emitter.service";
import { CommentEditFocusEvent, CommentReplyFocusEvent } from "../../models";
import { take, takeUntil } from "rxjs/operators";
import { AnnotationService } from "app/shared/services/annotation.service";
import { TranslatePipe } from "app/shared/pipes/translate.pipe";

export type QuillSubmitParams = {
  message: string;
  visibility: AnnotationPrivateCondition;
}

Quill.register('modules/emoji-shortname', QuillEmoji.ShortNameEmoji);
Quill.register('modules/mention', Mention);

@Component({
  selector: 'mtm-comment-editor',
  templateUrl: './mtm-comment-editor.component.html',
  styleUrls: ['./mtm-comment-editor.component.scss'],
})
export class MtmCommentEditorComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('mtmUpload', { static: false }) mtmUpload: UploadFileComponent;

  private projectService = inject(ProjectService);
  private modalService = inject(NgbModal);
  private annotationService = inject(AnnotationService);

  @Input() relatedCommentId: string = '';
  @Input() elementId: string = '';
  @Input() placeholder?: string = '';
  @Input() hideQuillToolbar: boolean = false;
  @Input() files: any = [];
  @Input() videoRecordFiles: any = [];
  @Input() audioRecordFiles: any = [];
  @Input() visibilitySelectorEnabled: boolean = true;
  @Input() filesToolbarVisible: boolean = true;
  @Input() cancelButtonId: string = 'btnCancelComment';
  @Input() cancelButtonEnabled: boolean = false;
  @Input() saveButtonEnabled: boolean = false;
  @Input() editButtonEnabled: boolean = false;
  @Output() enterKeydownPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() enterKeyPress: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileUpload: EventEmitter<any> = new EventEmitter();
  @Output() fileDelete: EventEmitter<any> = new EventEmitter();
  @Output() cancelClick: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<QuillSubmitParams> = new EventEmitter<QuillSubmitParams>();
  @Output() editorClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editClick: EventEmitter<any> = new EventEmitter<any>();

  modelValue: string;
  _commentVisibility: AnnotationPrivateCondition = null;

  @Input()
  get value() {
    return this.modelValue;
  };

  @Output() valueChange = new EventEmitter<string>();

  set value(val: string) {
    this.modelValue = val;
    this.valueChange.emit(this.modelValue);
  };

  @Input()
  get commentVisibility() {
    return this._commentVisibility;
  }

  @Output() commentVisibilityChange = new EventEmitter<AnnotationPrivateCondition>();
  set commentVisibility(val: AnnotationPrivateCondition) {
    this._commentVisibility = val;
    console.log('commentVisibility', val);
    this.commentVisibilityChange.emit(val);
  }

  attachmentsValue: any[];

  @Input()
  get attachments() {
    return this.attachmentsValue;
  }

  @Output() attachmentsChange = new EventEmitter();

  set attachments(val) {
    this.attachmentsValue = val;
    this.attachmentsChange.emit(this.attachmentsValue);
  }

  _backgroundColor: string = '';

  @Input()
  get backgroundColor(): string {
    return this._backgroundColor;
  }

  @Output() backgroundColorChange = new EventEmitter<string>();
  set backgroundColor(value: string) {
    this._backgroundColor = value;
    this.backgroundColorChange.emit(value);
    this.container.nativeElement.style.backgroundColor = value;
  }

  toolbarHidden: boolean = true;
  quillModule: any = null;
  quillInstance: any = null;
  ngUnsubscribe = new Subject<void>();

  constructor(private translatePipe: TranslatePipe) { }

  ngOnInit() {
    this.initQuill();

    EmitterService.get(CommentReplyFocusEvent).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const { parentId: parentCommentId } = data;
        if (parentCommentId === this.relatedCommentId) {
          setTimeout(() => {
            this.quillInstance?.focus()
          }, 10);
        }
      });
    EmitterService.get(CommentEditFocusEvent).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        const { commentId } = data;
        if (commentId === this.relatedCommentId) {
          setTimeout(() => {
            this.quillInstance?.focus();
          }, 10);
        }
      });

    if (this.visibilitySelectorEnabled) {
      this.annotationService.privateConditionChange$.pipe(take(1))
        .subscribe((value) => {
          this.commentVisibility = value;
        });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initQuill() {
    let __this = this;
    this.quillModule = {
      toolbar: this.hideQuillToolbar ? false : {
        container: [
          ['bold', 'italic', 'underline', 'link', 'emoji'],
        ],
        handlers: {
          link: (value) => {
            if (value) {
              const editorInstance = this.quillInstance;
              let range = editorInstance.selection;
              if (range.savedRange == null || range.savedRange.length === 0) return;
              let preview = __this.getSelectedText();
              const tooltip = editorInstance.theme.tooltip;
              tooltip.save = function () {
                let val = tooltip.textbox.value;
                if (val.indexOf('https') === -1 && val.indexOf('http') === -1) {
                  val = 'https://' + val;
                }
                this.quill.format('link', val, Emitter.sources.USER);
              };
              tooltip.edit("link", preview);
            }
          }
        }
      },
      'emoji-toolbar': true,
      keyboard: {
        bindings: {
          linebreak: {
            key: 13,
            shiftKey: false,
            handler: function () {
              __this.enterKeyPress.emit();
            }
          }
        }
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        positioningStrategy: 'fixed',
        mentionContainerClass: 'comment-editor-mention-container ql-mention-list-container',
        source: (searchTerm, renderList, mentionChar) => {
          let values = _.filter(_.map(_.filter(__this.projectService.project.participants, (usr) => _.get(usr, 'roles[0]', '') !== 'COMPANY_OCCASIONAL'), (participant) => {
            return {
              id: participant.username,
              value: participant.fullName,
              type: 'one_on_one'
            };
          }), (data) => !HelperService.isObjectEmpty(data.value.trim()));

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~HelperService.getNormalizedName(values[i].value).toLowerCase().indexOf(HelperService.getNormalizedName(searchTerm).toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        }
      }
    }
  }

  private setQuillTitleAttributes() {
    const toolbar = this.quillInstance.getModule('toolbar');
    const buttonTitles = {
      '.ql-bold': 'textEditorBoldText',
      '.ql-italic': 'textEditorItalicText',
      '.ql-underline': 'textEditorUnderlineText',
      '.ql-link': 'textEditorInsertLink',
      '.ql-emoji': 'textEditorInsertEmoji'
    };

    Object.keys(buttonTitles).forEach(key => {
      const button = toolbar.container.querySelector(key);
      if (button) {
        button.setAttribute('title', this.translatePipe.transform(buttonTitles[key]));
      }
    });
  }

  private getSelectedText() {
    let txt;
    if (window.getSelection) {
      txt = window.getSelection();
    } else if (window.document.getSelection) {
      txt = window.document.getSelection();
    } else if ((window.document as any).selection) {
      txt = (window.document as any).selection.createRange().text;
    }
    return txt;
  }

  toggleToolbar() {
    this.toolbarHidden = !this.toolbarHidden;
  }

  cancel() {
    console.log('cancel clicked');
    this.cancelClick.emit();
  }

  send() {
    this.submit.emit({
      message: this.value,
      visibility: null
    })
  }

  onEditorInitialized(editor: any) {
    this.quillInstance = editor;
    this.setQuillTitleAttributes();
  }

  toggleEmojiPicker() {
    const toolbar = this.quillInstance.getModule('toolbar');
    const emojiButton = toolbar.container.querySelector('.ql-emoji');

    if (emojiButton) {
      emojiButton.click();
    }
    this.triggerEditorClick();
  }

  openFileExplorer() {
    if (!this.mtmUpload) {
      console.log('mtmUpload not found');
      return;
    }

    this.triggerEditorClick();
    this.mtmUpload.fileInput.nativeElement.click();
  }

  deleteFile(event, file) {
    this.fileDelete.emit(file);
  }

  handleFileUpload(params: { files: any[] }) {
    this.fileUpload.emit(params);
  }

  openCameraPreviewPopup(e) {
    this.triggerEditorClick();
    let modal = this.modalService.open(VideoRecorderModalComponent, {
      //windowClass: 'video-recorder-modal',
      modalDialogClass: 'responsive-modal',
      backdrop: 'static',
      keyboard: false
    });
    modal.result.then(file => {
      if (file) {
        file.method = 'videoRecord';
        this.handleFileUpload({ files: [file] });
      }
    });
  }

  openAudioPopup(e) {
    this.triggerEditorClick();
    let modal = this.modalService.open(AudioRecorderModalComponent, {
      //windowClass: 'audio-recorder-modal',
      modalDialogClass: 'responsive-modal',
      backdrop: 'static',
      keyboard: false
    });
    modal.result.then(file => {
      if (file) {
        file.method = 'audioRecord';
        this.handleFileUpload({ files: [file] });
      }
    });
  }

  setVisibility(value: AnnotationPrivateCondition) {
    this.commentVisibility = value;
    this.annotationService.updatePrivateCondition(value);
  }

  triggerEditorClick() {
    this.editorClick.emit();
  }

  edit() {
    this.editClick.emit();
  }


}
