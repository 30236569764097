import * as _ from 'lodash';
import { AfterViewInit, Component, DoCheck, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AuthService } from 'app/shared/services/auth.service';
import {
	onAddingAnnotation,
	onAddingAnnotationDataChanged,
	onAddNewAnnotation,
	onAnnotationFocused,
	onAnnotationsLoaded,
	onAnnotationsStateChanged,
	onCancelAddingAnnotation,
	onCommentInputFocused,
	onPlayerReady,
	onVideoPaused,
	onVideoPlay,
	onVideoPlayerDisposed,
	onTimeFormatChange,
	onTimeFormatChangePlugin,
	onEditingAnnotation,
	onAnnotationClickOpened,
	onAnnotationClickClosed,
	onSaveEditedAnnotation,
	onAnnotationMarkerSelectedActiveClick,
	AnnotationService
} from "app/shared/services/annotation.service";
import { MtmStompService } from 'app/shared/services/mtm-stomp.service';
import { wsListenerANNOTATION_CREATED } from 'app/shared/services/mtm-websocket.service';
import { commentFileAdd } from 'app/shared/services/comments.service';
import { AnnotationPrivateCondition } from "../../../interfaces";
import { Subject, first, takeUntil } from 'rxjs';

@Component({
	selector: 'mtm-image-drawing-tools',
	templateUrl: './image-drawing-tools.component.html',
	styleUrls: ['./image-drawing-tools.component.scss']
})
export class ImageDrawingTools implements OnInit, DoCheck, OnDestroy, AfterViewInit {
	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() item: any = null;
	@Input() isExpandByDefault: boolean = false;
	@Input() projectParticipants: any[];
	@Input() annotationPlugin: any;

	@ViewChild('clearModeIcon') clearModeIcon: ElementRef;

	drawColor: string;
	isColorOpen: boolean = false;
	activeShape: string;
	isPalleteShow: boolean = true;
	isExpanded: boolean = true;
	annotationComment: string = '';
	privateCondition: AnnotationPrivateCondition = null;
	totalAnnotations: number = 0;
	activeTimeRange: string = '';
	startTime: number = 0;
	endTime: number = 0;
	player: any;
	isPlayerReady: boolean = false;
	isAnnotationActive: boolean = false;
	isEditActive: boolean = false;
	isAnnotationClicked: boolean = false;
	isVideoPaused: boolean = true;
	isTimeFormatOpen: boolean = false;
	activeTimeFormat: string = 'standard';
	metadata: any;
	videoMetadata: any;
	userAvatarUrl: string;
	authUser: any;
	userInfo: any;
	activeAnnotation: any;
	editingAnnotation: any;
	annotationToolbarActive: boolean = true;
	wsId: string;
	newFiles: any = [];

	files: any = [];
	videoRecordFiles: any = [];
	audioRecordFiles: any = [];
	ngUnsubscribe = new Subject();
	isBusy: boolean = false;
	generalCommentingMode: boolean;

	constructor(
		private stompService: MtmStompService,
		private authService: AuthService,
		private annotationService: AnnotationService
	) {
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next(undefined);
		this.ngUnsubscribe.complete();
		this.stompService.unsubscribeToService(this.wsId);
	}

	ngAfterViewInit(): void {
		this.wsId = this.stompService.subscribeToService();
		onAnnotationsLoaded.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.checkAnnotationPluginState(args)
		})
		onAnnotationsStateChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationStateChanged(args)
		})
		onAddingAnnotationDataChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationDataChanged(args)
		})
		onAnnotationFocused.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.cancelAddingAnnotationIfActive(args)
		})
		onCommentInputFocused.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.cancelAddingAnnotationIfActive(args)
		})
		onPlayerReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onPlayerReadyHandler(args)
		})
		onVideoPlayerDisposed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPlayerDisposed(args)
		})
		onAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAddingAnnotationHandler(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onEditingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onEditingAnnotationHandler(args)
		})
		onAnnotationClickOpened.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationClickOpenedHandler(args)
		})
		onAnnotationClickClosed.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onAnnotationClickClosedHandler(args)
		})
		onVideoPaused.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPausedHandler(args)
		})
		onVideoPlay.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onVideoPlayHandler(args)
		})
		onTimeFormatChangePlugin.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.changeTimeFormatPlugin(args)
		})

		this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);
	}

	ngOnInit(): void {
		this.authUser = this.authService.getAuthUser();
		this.userInfo = _.find(this.projectParticipants, { username: this.authUser.username }) as any;
		// window["sh"] = this;
		this.annotationService.privateConditionChange$.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (value: AnnotationPrivateCondition) => {
				this.privateCondition = value;
			}
		})
	}

	ngDoCheck(): void {
		if (this.player && this.isPlayerReady) {
			if (!this.isAnnotationActive && !this.isEditActive && !this.isAnnotationClicked) {
				this.startTime = this.player.currentTime();
				this.endTime = 0;
			} else {
				if (!this.isVideoPaused) {
					this.startTime = this.player.currentTime();
					this.endTime = 0;
				}
			}
		}
	}

	wsOnAnnotationCreate(args) {
		commentFileAdd.emit({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			comment: args.mediaAnnotation.comments[0],
			annotationId: args.mediaAnnotation.id,
			files: this.newFiles
		});
		this.cleanUpFiles();
	}

	checkInvalidUser() {
		let roles = this.userInfo?.roles || [];
		let isInvalidUser = false;
		const excludedRoles = ["PROJECT_STAFF", "PROJECT_SENIOR_STAFF", "PRODUCTION_STAFF", "PRODUCTION_SENIOR_STAFF"];
		const filteredRoles = roles.filter(role => !excludedRoles.includes(role));
		isInvalidUser = filteredRoles.length === 0 && this.authUser?.globalRole != "COMPANY_OCCASIONAL";
		return isInvalidUser;
	}

	checkAnnotationPluginState(args: any) {
		const handleFn = () => {
			this.onAnnotationsLoaded(args);
		};

		const interval = setInterval(() => {
			if (this.annotationPlugin?.player && this.annotationPlugin?.annotationState) {
				handleFn();
				clearInterval(interval);
			}
		}, 100);
	}

	onAnnotationsLoaded(args: any) {
		if (args.data) {
			//TODO: find the best way to implement
			if (this.annotationPlugin.player?.canvasFabric) {
				this.annotationPlugin.player.canvasFabric.clear();
			}

			this.annotationPlugin.annotationState.annotations = args.data;
		}
		if ((this.projectId === args.projectId) && (this.sectionId === args.sectionId) && (this.subsectionId === args.subSectionId) && (this.typeId === args.typeId) && (this.item.id === args.itemId)) {
			this.totalAnnotations = args.data.length;
		}
	}

	onAnnotationStateChanged(args: any) {
		if ((this.projectId === args.projectId) && (this.sectionId === args.sectionId) && (this.subsectionId === args.subSectionId) && (this.typeId === args.typeId) && (this.item.id === args.itemId)) {
			this.totalAnnotations = args.data.length;
		}
	}

	onAddingAnnotationHandler(args: any) {
		this.isAnnotationActive = true;
	}

	onEditingAnnotationHandler(args: any) {
		this.isEditActive = true;
	}

	onCancelAddingAnnotationHandler(args?: any) {
		this.resetComponent(true);
		this.cleanUpFiles();
		if (this.generalCommentingMode) this.resetComponent();
	}

	onVideoPausedHandler(args: any) {
		this.isVideoPaused = true;
	}

	onVideoPlayHandler(args: any) {
		this.isVideoPaused = false;
	}

	toolbarClicked(type: string) {
		if (type === 'color') {
			this.isColorOpen = false;
		} else {
			if (type !== 'undo' && type !== 'redo' && type !== 'clear') {
				this.activeShape = type;
			}
		}
	}

	showPalleteClick() {
		this.isPalleteShow = !this.isPalleteShow;
	}

	onAddingAnnotationDataChanged(args: any) {
		if (args.data.range) {
			this.startTime = args.data.range.start;
			this.endTime = args.data.range.end;
		}
	}

	cancelAddingAnnotationIfActive(args: any) {
		if (this.isAnnotationActive) {
			onCancelAddingAnnotation.emit('cancelAddingAnnotation');
		}
	}

	onAnnotationClickOpenedHandler(args: any) {
		if (!this.isAnnotationClicked) {
			this.activeAnnotation = args.annotation;
			this.isAnnotationClicked = true;
			this.isAnnotActiveUser();
		}
	}

	onAnnotationClickClosedHandler(args: any) {
		if (this.isAnnotationClicked) {
			this.activeAnnotation = null;
			this.isAnnotationClicked = false;
		}
	}

	onPlayerReadyHandler(args: any) {
		if (args.player) {
			this.player = args.player;
			this.isPlayerReady = true;
			this.isExpanded = this.isExpandByDefault;
			this.metadata = args.metadata;
			this.videoMetadata = JSON.parse(this.metadata).filter(element => {
				if (element.codecType === 'VIDEO') return element;
			})[0];
		}
	}

	onVideoPlayerDisposed(args: any) {
		if (args.player) {
			this.player = args.player;
			this.isPlayerReady = false;
		}
	}

	createAnnotation() {
		if (!this.isPlayerReady || !this.player || this.isEditActive) {
			return;
		}
		if (!this.player.el().classList.contains('vjs-has-started')) {
			this.player.el().classList.add('vjs-has-started');
		}
		this.isExpanded = !this.isExpanded;
		if (this.isExpanded) {
			this.startTime = this.player.currentTime();
			this.endTime = 0;
		} else {
			onCancelAddingAnnotation.emit('cancelAddingAnnotation');
			this.resetComponent();
		}
	}

	editAnnotation() {
		if (this.activeAnnotation && this.isAnnotationClicked) {
			this.isEditActive = true;
			this.editingAnnotation = this.activeAnnotation;
			onEditingAnnotation.emit({
				act: 'editAnnotation',
				annotation: this.activeAnnotation,
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id
			});
			this.annotationComment = this.editingAnnotation.comments[0].body;
		}
	}

	saveAnnotation() {
		if ((!this.annotationComment && !this.newFiles?.length) || this.isBusy) {
			return;
		}
		this.isBusy = true;
		this.annotationService.updateCommentListVisibility(this.privateCondition);
		if (this.isEditActive && this.editingAnnotation) {
			let body = this.annotationComment;
			if (this.startTime > this.endTime) {
				this.endTime = this.startTime;
			}
			onSaveEditedAnnotation.emit({
				act: 'saveEditedAnnotation',
				annotationId: this.editingAnnotation.id,
				data: {
					...this.editingAnnotation,
					range: {
						start: parseFloat(this.startTime.toFixed(2)),
						end: parseFloat(this.endTime.toFixed(2))
					},
					commentStr: body

				},
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id,
				privateConditionKey: this.privateCondition
			});
			// onAnnotationMarkerSelectedActiveClick.emit({
			// 	annotation: this.editingAnnotation
			// });

		}
		else if (this.annotationPlugin && !this.annotationPlugin.controls.uiState?.adding && !this.annotationPlugin.controls.uiState?.editing) {
			let body = this.annotationComment;

			onAddNewAnnotation.emit({
				act: 'newAnnotation',
				annotation: {
					range: {
						start: 0.01,
						end: 0.01,
						isGeneralComment: true
					},
					commentStr: body,
					privateConditionKey: this.privateCondition
				},
				projectId: this.projectId,
				sectionId: this.sectionId,
				subSectionId: this.subsectionId,
				typeId: this.typeId,
				itemId: this.item.id,
			});
			this.generalCommentingMode = false;
		}
		else {
			this.addNewAnnotation()
		}
		this.resetComponent();
	}

	addNewAnnotation() {
		if (!this.annotationComment && !this.isPlayerReady || this.isInValidInput()) {
			return;
		}
		if (this.newFiles.length && !this.annotationComment) {
			this.annotationComment = ' ';
		}
		let body = this.annotationComment;
		if (this.startTime > this.endTime) {
			this.endTime = this.startTime;
		}
		onAddNewAnnotation.emit({
			act: 'newAnnotation',
			annotation: {
				range: {
					start: parseFloat(this.startTime.toFixed(2)),
					end: parseFloat(this.endTime.toFixed(2))
				},
				commentStr: body,
				privateConditionKey: this.privateCondition
			},
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			typeId: this.typeId,
			itemId: this.item.id,
		});
	}

	resetComponent(isCancelAddingAnnotation?: boolean) {
		if (!isCancelAddingAnnotation) {
			this.startTime = 0;
		}
		this.annotationComment = '';
		this.isColorOpen = false;
		this.isPalleteShow = true;
		this.endTime = this.startTime;
		this.activeShape = '';
		this.isAnnotationActive = false;
		this.isEditActive = false;
		this.isBusy = false;
		this.generalCommentingMode = false;
	}

	onStartTimeRangeChange(act: string) {
		if (this.activeTimeRange !== 'startTime') {
			this.startTime = this.player.currentTime();
		}
		this.activeTimeRange = 'startTime';
		if (act === 'add') {
			this.startTime++;
		} else {
			this.startTime--;
			if (this.startTime < 0) {
				this.startTime = 0;
			}
		}
		if (this.startTime >= this.endTime) {
			this.endTime = this.startTime;
		}
		if (this.startTime > this.player.duration()) {
			this.startTime = this.player.duration();
		}
	}

	onEndTimeRangeChange(act: string) {
		if (this.activeTimeRange !== 'endTime') {
			this.endTime = this.startTime;
		}
		this.activeTimeRange = 'endTime';
		if (act === 'add') {
			this.endTime++;
		} else {
			this.endTime--;
			if (this.endTime < 0) {
				this.endTime = 0;
			}
		}
		if (this.endTime > this.player.duration()) {
			this.endTime = this.player.duration();
		}
		if (this.endTime < this.startTime) {
			this.endTime = this.startTime;
		}
	}


	getTimeDisplay(time: any, isStart: boolean) {
		let timeDisplay = new Date(parseFloat(time) * 1000).toISOString().substr(14, 9);
		if (this.annotationPlugin && this.annotationPlugin.controls && !this.annotationPlugin.controls.uiState.adding && !this.annotationPlugin.controls.uiState.editing) {
			timeDisplay = '--:--';
		}
		if (timeDisplay === '00:00.000' && !isStart) {
			timeDisplay = '--:--';
		}
		if (!isStart && (this.startTime === this.endTime)) {
			timeDisplay = '--:--';
		}
		if (this.activeTimeFormat === 'frames') {
			// Evaluate "x/y" string expressions
			let videoFrameRate = this.videoMetadata.avgFrameRate.split('/').map(x => +x).reduce((a, b) => a / b);
			timeDisplay = (Math.floor(time * videoFrameRate)).toString();
			if (timeDisplay === '0' && !isStart) {
				timeDisplay = '-';
			}
			if (!isStart && (this.startTime === this.endTime)) {
				timeDisplay = '-';
			}
		}
		return timeDisplay;
	}


	// Change time format event fired to the plugin
	changeTimeFormat(format: string) {
		this.activeTimeFormat = format;
		onTimeFormatChange.emit({
			act: 'changeTimeFormat',
			format: format
		});
	}


	// Change time format event fired from the plugin
	changeTimeFormatPlugin(args: any) {
		let format = args.format;
		this.activeTimeFormat = format;
		this.isTimeFormatOpen = false;
	}

	isInValidInput() {
		return !this.annotationComment && !this.newFiles.length;
	}

	isAnnotActiveUser() {
		let isValid = false;
		if (this.activeAnnotation && this.isAnnotationClicked) {
			if (this.activeAnnotation.comments[0].meta.user_id === this.authUser.username) {
				isValid = true;
			}
		}
		return isValid;
	}

	/** Gets the comment input place holder text depending on general or annot comment */
	getPlaceholderText() {
		let placeholderText;
		if (this.annotationPlugin && this.annotationPlugin.controls && (this.annotationPlugin.controls.uiState.adding || this.annotationPlugin.controls.uiState.editing || this.annotationToolbarActive)) {
			placeholderText = "Write an annotation comment..."
		}
		else {
			placeholderText = "Write a general comment..."
		}

		return placeholderText;
	}

	/** Toggle annotation toolbar display on/off using toggle */
	toggleAnnotationToolbarDisplay(annotationToolbarActive) {
		if (annotationToolbarActive == true) {
			this.annotationPlugin?.fire("togglePiklorColors", true); // Changes colors in palette to depict greyed out

		}
		else {
			this.clearModeIcon?.nativeElement.click(); // Triggers canceladdnew event on the plugin
			this.onCancelAddingAnnotationHandler();
			this.annotationPlugin?.fire("togglePiklorColors", false);
		}
	}

	/** Starts annotation mode */
	startAnnotationMode() {
		const canAnnotate = !this.isAnnotationActive && this.annotationPlugin && this.isPlayerReady && !this.isEditActive;

		if (canAnnotate && this.annotationToolbarActive) {
			this.annotationPlugin.fire("addingAnnotation");
		}
		else if (canAnnotate && !this.annotationToolbarActive) {
			this.generalCommentingMode = true;
		}
	}

	cleanUpFiles() {
		this.newFiles = [];
		this.files = [];
		this.videoRecordFiles = [];
		this.audioRecordFiles = [];
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		files.forEach(file => {
			if (file.method == 'audioRecord') {
				this.audioRecordFiles.push(file);
			} else if (file.method == 'videoRecord') {
				this.videoRecordFiles.push(file);
			} else {
				this.files.push(file);
			}
		});
		this.newFiles.push(...files);
	}

	onFileDeleted(file) {
		this.newFiles = this.newFiles.filter(f => f.lastModified != file.lastModified);
		this.audioRecordFiles = this.newFiles.filter(f => f.method == 'audioRecord');
		this.videoRecordFiles = this.newFiles.filter(f => f.method == 'videoRecord');
		this.files = this.newFiles.filter(f => !f.method);
	}

	handlePrivateConditionChange(value: AnnotationPrivateCondition) {
		this.annotationService.updatePrivateCondition(value);
	}

}
