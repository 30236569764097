import {
	Component,
	ElementRef,
	inject,
	Input,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewEncapsulation
} from "@angular/core";
import {
	AnnotationService,
	onAddingAnnotationDataChanged,
	onAnnotationClickClosed,
	onAnnotationClickedIn,
	onAnnotationClickedOut,
	onAnnotationClickOpened,
	onAnnotationHoveredIn,
	onAnnotationHoveredOut,
	onAnnotationsLoaded,
	onAudioPluginReady,
	onCancelAddingAnnotation,
	onFirstRowMarkersReady
} from "app/shared/services/annotation.service";
import { AudioAnnotationPlugin } from "./plugin/audio-annotation.plugin";
import * as _ from "lodash";
import { EmitterService } from "app/shared/services/emitter.service";
import { commentFileAdd } from "app/shared/services/comments.service";
import { wsListenerANNOTATION_CREATED } from "app/shared/services/mtm-websocket.service";
import { MtmStompService } from "app/shared/services/mtm-stomp.service";
import { AnnotationPrivateCondition, OptionInfo, QueryState } from "../../interfaces";
import { first, takeUntil, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { ActiveSubsectionState, SubsectionService } from "app/shared/services/subsection.service";
import { TranslatePipe } from 'app/shared/pipes/translate.pipe';
import { OverlayService } from 'app/shared/services/overlayService';
import { SubsectionVersionListComponent } from '../subsection-version-list/subsection-version-list.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OptionUpdatedEvent } from "app/shared/models";
import { ReviewUiLinkSettingsComponent } from "../review-link/review-link-settings/review-link-settings.component";
import { ProjectService } from "app/shared/services/project.service";
import { AuthService } from "app/shared/services/auth.service";
import { MTMFileDownloadControl } from "../mtm-file-download/mtm-file-download-control/mtm-file-download-control.component";
import { SubsectionOptionEditComponent } from "../../../project-subsection/options/edit/subsection-option-edit.component";
import { Authority, PermissionService } from "app/shared/services/permissions.service";

@Component({
	selector: 'mtm-audio-annotation',
	styleUrls: ['./audio-annotation.component.scss'],
	encapsulation: ViewEncapsulation.None,
	templateUrl: './audio-annotation.component.html'
})
export class AudioAnnotation implements OnInit, OnDestroy {
	@ViewChild('downloadButton') downloadButton: MTMFileDownloadControl;
	private subsectionService = inject(SubsectionService);
	private translatePipe = inject(TranslatePipe);
	private modalService = inject(NgbModal);
	private overlayService = inject(OverlayService);
	private projectService = inject(ProjectService);
	private permissionService = inject(PermissionService);

	@Input() projectId: any;
	@Input() sectionId: any;
	@Input() projectParticipants: any[];
	@Input() subsectionId: any;
	@Input() typeId: any;
	@Input() item: any;
	@Input() annotations: any[];
	@Input() authUser: any;
	@Input() hideAnnotationToolbar: boolean = true;
	@Input() hideTopMediaInfo: boolean = false;
	@Input() hasEditPermission: boolean = false;
	@Input() topMediaInfoTemplate: TemplateRef<any>;
	@Input() assetListTemplate: TemplateRef<any>;
	@Input() assetInfoTemplate: TemplateRef<any>;
	@ViewChild('elapsedMask') elapsedMask: ElementRef;
	@ViewChild('selectableRange') selectableRange: ElementRef;
	@ViewChild('remainingMask') remainingMask: ElementRef;
	@ViewChild('waveformContainer') waveformContainer!: ElementRef;
	@ViewChild('postWaveform') postWaveform: ElementRef;

	playerState: any = "stopped";
	amplitudeReady: boolean = false;
	audioDuration: any;
	audioStep: any;
	elapsedWidth: any;
	annotationPlugin: AudioAnnotationPlugin;
	pluginReady: boolean;
	annotatingMode: boolean = false;
	annotationComment: any = '';
	isAnnotationDisplayEnabled: boolean = false;
	activeAnnotation: any;
	startTime: any;
	endTime: any;
	isTimeFormatOpen: boolean = false;
	isBottomTimeFormatOpen: boolean = false;
	activeTimeFormat: string = 'seconds';
	volume: any = 100;
	volumeMute: boolean = false;
	sampleRate: number = 250;
	isWaveformCreated: boolean = false;
	waveformPath: any;
	maskWaveform: any;
	wavesurfer: any;
	activeTimeMode: string;
	markerAvatarSize: number = 28;
	prevVolume: number;
	muteClick: boolean = false;
	progressBarMarkers: any;
	wavesurferPre: any;
	wavesurferPost: any;
	preWaveFormReady: boolean = false;
	postWaveFormReady: boolean = false;
	waveFormsDisplay: boolean = false;
	isPlaying: boolean = false; //Handled in plugin
	tempTime: any;
	userInfo: any;
	annotationToolbarActive: boolean = true;
	wsId: string;
	newFiles: any = [];
	privateCondition: AnnotationPrivateCondition = null;


	files: any = [];
	videoRecordFiles: any = [];
	audioRecordFiles: any = [];
	ngUnsubscribe = new Subject();
	isBusy: boolean = false;
	subSectionState: ActiveSubsectionState;
	isAssetListVisible: boolean;
	optionsState: QueryState<OptionInfo>;

	private resizeObserver!: ResizeObserver;
	resizeDebounceTime = 500 // in ms
	private OnResize = _.debounce((width, height) => {
		this.resizeWaveforms();
	}, this.resizeDebounceTime);
	generalCommentingMode: boolean;

	constructor(private stompService: MtmStompService, private annotationService: AnnotationService, private authService: AuthService) {
	}

	ngAfterViewInit(): void {
		//Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
		//Add 'implements AfterViewInit' to the class.
		this.wsId = this.stompService.subscribeToService();
		onAnnotationsLoaded.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateMarker(args)
		})
		onAddingAnnotationDataChanged.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.updateTimeRange(args)
		})
		onCancelAddingAnnotation.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.onCancelAddingAnnotationHandler(args)
		})
		onFirstRowMarkersReady.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.setFirstRowMarkers(args)
		})
		onAnnotationHoveredIn.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.activateWaveforms(args)
		})
		onAnnotationHoveredOut.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.deactivateWaveforms(args)
		})
		onAnnotationClickedIn.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.activateWaveforms(args)
		})
		onAnnotationClickedOut.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (args: any) => this.deactivateWaveforms(args)
		})

		this.stompService.subscribeToListener('ANNOTATION_CREATED', this.wsId, [this.projectId, this.sectionId, this.subsectionId],
			(args: any) => this.wsOnAnnotationCreate(args), wsListenerANNOTATION_CREATED);


		this.resizeObserver = new ResizeObserver(entries => {
			for (let entry of entries) {
				// Check if the current entry's target is the resizeDiv element
				if (entry.target === this.waveformContainer.nativeElement && this.annotationPlugin && this.pluginReady) {
					let width = entry.contentRect.width;
					let height = entry.contentRect.height;
					this.OnResize(width, height);
				}
			}
		});

		this.resizeObserver.observe(this.waveformContainer.nativeElement)
	}

	ngOnInit(): void {
		window["aa"] = this;
		this.authUser = this.authService.getAuthUser();
		this.userInfo = _.find(this.projectParticipants, { username: this.authUser.username }) as any;
		this.annotationService.privateConditionChange$.pipe(
			takeUntil(this.ngUnsubscribe)
		).subscribe({
			next: (value: AnnotationPrivateCondition) => {
				this.privateCondition = value;
			}
		})

		this.subsectionService.subsection$
			.pipe(takeUntil(this.ngUnsubscribe),
				distinctUntilChanged((prev, curr) =>
					prev.activeOption?.optionId === curr.activeOption?.optionId && prev.activeVersion?.versionNumber === curr.activeVersion?.versionNumber))
			.subscribe({
				next: (state) => {
					this.subSectionState = state;
				}
			});

		this.subsectionService.options$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe({
				next: (options) => {
					this.optionsState = options;

				}
			});

		this.subsectionService.subsection$
			.pipe(takeUntil(this.ngUnsubscribe),
				distinctUntilChanged((prev, curr) => prev.activeFile === curr.activeFile))
			.subscribe({
				next: (state) => {
					this.subSectionState = state;
				}
			});
		this.initAudio();
	}

	ngOnDestroy(): void {
		$(document).off('keydown');
		this.ngUnsubscribe.next(undefined);;
		this.ngUnsubscribe.complete();
		this.stompService.unsubscribeToService(this.wsId);
	}

	checkInvalidUser() {
		let roles = this.userInfo?.roles || [];
		let isInvalidUser = false;
		const excludedRoles = ["PROJECT_STAFF", "PROJECT_SENIOR_STAFF", "PRODUCTION_STAFF", "PRODUCTION_SENIOR_STAFF"];
		const filteredRoles = roles.filter(role => !excludedRoles.includes(role));
		isInvalidUser = filteredRoles.length === 0 && this.authUser?.globalRole != "COMPANY_OCCASIONAL";
		return isInvalidUser;
	}

	// WAVEFORM SECTION
	checkWaveformCreated() {
		let check = setInterval(() => {
			let waveformPath = document.getElementById("waveform").getAttribute("d");
			if (waveformPath != "") {
				this.isWaveformCreated = true;
				this.waveformPath = waveformPath.split(", ");
				this.initWaveformMask();
				clearInterval(check);
			}
		}, 100);
	}

	wsOnAnnotationCreate(args) {
		commentFileAdd.emit({
			projectId: this.projectId,
			sectionId: this.sectionId,
			subSectionId: this.subsectionId,
			comment: args.mediaAnnotation.comments[0],
			annotationId: args.mediaAnnotation.id,
			files: this.newFiles
		});
		this.cleanUpFiles();
	}

	initWaveformMask() {
		let maskEl = this.waveformContainer.nativeElement.firstElementChild.cloneNode(true);
		maskEl.classList.add("mask-waveform");
		this.maskWaveform = maskEl.querySelector("#waveform");
		this.maskWaveform.setAttribute("id", "maskWaveform");
		this.updateWaveform();
		this.waveformContainer.nativeElement.appendChild(maskEl);
	}

	updateWaveform() {
		let currentPercentage = Amplitude.getSongPlayedPercentage();
		let scale = this.sampleRate / 100;
		let waveBars = Math.round(currentPercentage * scale);
		let maskPath = this.waveformPath.slice(0, waveBars * 2 + 1);
		maskPath.push(this.waveformPath[this.waveformPath.length - 1]);
		this.maskWaveform.setAttribute("d", maskPath.join(", "));
	}

	updateProgressBar() {
		let currentTime = Amplitude.getSongPlayedSeconds();
		this.wavesurfer.setCurrentTime(currentTime);
		let progressEl = document.getElementById("progress-navigation");
		progressEl["value"] = Amplitude.getSongPlayedSeconds();

		this.annotationPlugin.fire('timeUpdate');
	}

	onAmplitudeInit() {
		this.audioDuration = Amplitude.getSongDuration();
		this.audioStep = 0.01;
		let config = {
			annotationObjects: this.annotations,
			item: this.item,
			userInfo: this.authUser
		};
		this.amplitudeReady = true;
		Amplitude.setVolume(100);
		this.annotationPlugin = new AudioAnnotationPlugin(this, config);
		this.annotationPlugin.onReady(() => {
			onAudioPluginReady.emit({
				annotationPlugin: this.annotationPlugin
			});
			this.pluginReady = true;
			this.bindCloseAnnotationKey();
			this.updateMarkerDimensions();
		});
		// this.checkWaveformCreated();
	}

	updateAudioTime(time) {
		let percentage = (time / this.audioDuration) * 100;
		Amplitude.setSongPlayedPercentage(percentage);
		// this.wavesurfer.setCurrentTime(time);
	}

	audioPausePlay() {
		this.wavesurfer.playPause();
	}

	inputClickChange() {
		this.annotationPlugin.fire('timeUpdate');
	}

	updateMarker(args) {
		if (args.itemId == this.item.id) {
			this.annotations = args.data;
			this.annotationPlugin.annotationState.annotations = args.data;
		}
		if (_.get(this.activeAnnotation, 'annotation')) {
			this.activeAnnotation.annotation = _.find(args.data, { id: _.get(this.activeAnnotation, 'annotation.id') });
			if (args.previewAnnotation && this.activeAnnotation.annotation) {
				args.previewAnnotation = this.activeAnnotation.annotation;
			}
		}
		this.updateMarkerDimensions();
	}

	updateMarkerDimensions() {
		this.annotations.forEach(annotation => {
			annotation.left = this.getPercentage(annotation.range.start);
			annotation.width = this.getPercentage(annotation.range.end - annotation.range.start);
			let leftWidth = annotation.left;
			let width = annotation.width;
			let rigthWidth = 100 - (leftWidth + width);
			if (leftWidth > rigthWidth) {
				annotation.markerPos = 'left';
			}
			else if (leftWidth <= rigthWidth) {
				annotation.markerPos = 'right';
			}
			annotation.markerLeftSet = false;
		});
	}

	getPercentage(val) {
		return (val / Amplitude.getSongDuration()) * 100;
	}

	getAnnotationZIndex(annotation: any, element) {
		annotation.markerElement = element;
		return _.get(annotation, 'range.start', 0) * 10 + 20;
	}

	hoverInAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverInAnnotation', { annotation: annotation });
		}
	}

	hoverOutAnnotation(annotation) {
		if (this.annotationPlugin && this.pluginReady && (!this.activeAnnotation || this.activeAnnotation.type === 'hover')) {
			this.annotationPlugin.fire('hoverOutAnnotation', { annotation: annotation });
		}
	}

	annotationBarClick(annotation, event) {
		if (event.classList.contains("close-marker")) return;
		let _this2 = this;
		if (this.annotationPlugin && this.pluginReady && this.preWaveFormReady && this.postWaveFormReady) {
			if (this.activeAnnotation && annotation.id === this.activeAnnotation.annotation.id && this.activeAnnotation.type === "click") {
				this.annotationPlugin.fire('clickOutAnnotation');
			}
			else {
				this.annotationPlugin.fire('clickInAnnotation', { annotation: annotation });
			}
		}
	}

	closeActiveAnnotation() {
		if (this.annotationPlugin && this.pluginReady && this.preWaveFormReady && this.postWaveFormReady) {
			if (this.activeAnnotation && this.activeAnnotation.type === "click") {
				this.annotationPlugin.fire('clickOutAnnotation');
			}
		}
	}

	bindCloseAnnotationKey() {
		let _this2 = this;
		$(document).on('keydown', e => {
			if (e.key === "Escape" && this.activeAnnotation && this.activeAnnotation.type === "click") {
				_this2.closeActiveAnnotation();
			}
		});
	}

	initialiseTemplateWave() {
		/* Creates 2 waveforms to represent the pre and post waveform in another color
		when an annotation is selected */
		let _this2 = this;
		this.wavesurferPre = WaveSurfer.create({
			container: '#waveform2',
			waveColor: 'transparent',
			progressColor: '#3d3939',
			barWidth: 1,
			height: 200,
			backend: 'MediaElement'
		});
		this.wavesurferPost = WaveSurfer.create({
			container: '#waveform3',
			waveColor: '#3d3939',
			progressColor: 'transparent',
			barWidth: 1,
			height: 200,
			backend: 'MediaElement'
		});

		this.wavesurferPre.load(this.item.signedURL, this.wavesurfer.backend.mergedPeaks);
		this.wavesurferPost.load(this.item.signedURL, this.wavesurfer.backend.mergedPeaks);

		this.wavesurferPre.on('ready', () => {
			_this2.preWaveFormReady = true;
		});
		this.wavesurferPost.on('ready', () => {
			_this2.postWaveFormReady = true;
		});
	}

	initAudio() {
		let _this2 = this;

		Amplitude.init({
			"bindings": {
				39: null,
				37: null
			},
			"debug": false,
			"waveforms": {
				sample_rate: this.sampleRate
			},
			"songs": [
				{
					"url": this.item.signedURL
				}
			],
			preload: "auto",
			callbacks: {
				play: function () {
					_this2.playerState = Amplitude.getPlayerState();
					_this2.audioPlayHandler();
				},
				pause: function () {
					_this2.playerState = Amplitude.getPlayerState();
					_this2.audioBreakHandler();
				},
				stop: function () {
					_this2.playerState = Amplitude.getPlayerState();
					_this2.audioBreakHandler();
				},
				loadeddata: function () {
					_this2.onAmplitudeInit();
				},
				timeupdate: function () {
					_this2.updateProgressBar();
					// _this2.updateWaveform();
				}
				// volumechange: function() {
				// 	_this2.updateVolume();
				// }
			}
		});
		let token = localStorage.getItem('token');
		this.wavesurfer = WaveSurfer.create({
			container: '#waveform',
			waveColor: '#909091',
			progressColor: '#fe3b00',
			barWidth: 1,
			cursorWidth: 3,
			cursorColor: "white",
			height: 200,
			xhr: {
				requestHeaders: [
					{ key: "Authorization", value: "Bearer " + token }
				]
			}
		});
		this.wavesurfer.load(this.item.signedURL);
		// this.wavesurfer.on('load', _this2.onAmplitudeInit());
		this.wavesurfer.on('ready', () => {
			let peaks = _this2.wavesurfer.backend.mergedPeaks;
			_this2.initialiseTemplateWave();
		});
		this.playerState = Amplitude.getPlayerState();
		EmitterService.get('audioAnnotation.ready').emit();
	}

	audioPlayHandler() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('playAudioHandler');
		}
	}

	audioBreakHandler() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('breakAudioHandler');
		}
	}

	startAnnotationMode() {
		const canAnnotate = !this.annotatingMode && this.annotationPlugin && this.pluginReady;
		
		if (canAnnotate && this.annotationToolbarActive) {
			this.annotationPlugin.fire('addingAnnotation');
		} else if (canAnnotate && !this.annotationToolbarActive) {
			this.generalCommentingMode = true;
		}
	}

	canceladdNewAnnotation() {
		if (this.annotationPlugin && this.annotatingMode && this.pluginReady) {
			this.annotationPlugin.fire('cancelAddingAnnotation');
		}
		if (this.generalCommentingMode) {
			this.onCancelAddingAnnotationHandler();
		}
	}

	addNewAnnotation() {
		if ((!this.annotationComment && !this.newFiles?.length) || this.isBusy) {
			return;
		}
		this.isBusy = true;
		if (this.isInValidInput()) return;

		if (this.newFiles.length && !this.annotationComment) {
			this.annotationComment = ' ';
		}
		if (this.annotationPlugin && this.annotatingMode && this.pluginReady) {
			this.annotationPlugin.fire('saveAnnotation');

		}
		else if (this.annotationPlugin && !this.annotatingMode && this.pluginReady) {
			this.annotationPlugin.fire('saveGeneralComment');
			this.generalCommentingMode = false;
		}
	}

	onCancelAddingAnnotationHandler(args?: any) {
		this.annotationComment = '';
		this.annotatingMode = false;
		this.isBusy = false;
		this.generalCommentingMode = false;
	}

	isInValidInput() {
		return !this.annotationComment && !this.newFiles.length;
	}

	updateTimeRange(args) {
		if (this.annotationPlugin && this.pluginReady && this.annotatingMode === true && args.itemId === this.item.id) {
			this.startTime = args.data.range.start;
			this.endTime = args.data.range.end;
		}
	}

	// Time Range Section //

	getStartTime() {
		if (this.annotatingMode === false) {
			this.startTime = Amplitude.getSongPlayedSeconds();
		}
		else {
			this.startTime = this.startTime;
		}

		return this.getTimeFormat(this.startTime);
	}

	getEndTime() {
		if (this.annotatingMode === false) {
			this.endTime = this.startTime;
		}
		else {
			this.endTime = this.endTime;
		}
		if (this.endTime === this.startTime) {
			if (this.activeTimeFormat === 'seconds') {
				return "--:--";
			}
			else if (this.activeTimeFormat === 'milliseconds') {
				return "--:--:---"
			}
		}

		return this.getTimeFormat(this.endTime);
	}

	getTimeFormat(time) {
		if (this.annotatingMode === false) {
			if (this.activeTimeFormat === 'seconds') {
				return "--:--";
			}
			else if (this.activeTimeFormat === 'milliseconds') {
				return "--:--:---"
			}
		}
		if (this.activeTimeFormat === 'seconds') {
			return new Date(time * 1000).toISOString().substr(14, 5);
		}
		else if (this.activeTimeFormat === 'milliseconds') {
			return new Date(time * 1000).toISOString().substr(14, 9).replace('.', ':');
		}
	}

	getCurrentTime() {
		let currentTime = Amplitude.getSongPlayedSeconds();
		if (this.activeTimeFormat === 'seconds') {
			return new Date(currentTime * 1000).toISOString().substr(14, 5);
		}
		else if (this.activeTimeFormat === 'milliseconds') {
			return new Date(currentTime * 1000).toISOString().substr(14, 9).replace('.', ':');
		}
	}

	getAudioDuration() {
		if (this.activeTimeFormat === 'seconds') {
			return new Date(this.audioDuration * 1000).toISOString().substr(14, 5);
		}
		else if (this.activeTimeFormat === 'milliseconds') {
			return new Date(this.audioDuration * 1000).toISOString().substr(14, 9).replace('.', ':');
		}
	}

	changeTimeFormat(format, location) {
		this.activeTimeFormat = format;
		if (location === "top") {
			if (this.isBottomTimeFormatOpen) this.isBottomTimeFormatOpen = false;
		}
		else if (location === "bottom") {
			if (this.isTimeFormatOpen) this.isTimeFormatOpen = false;
		}
	}

	changeActiveTimeMode(mode) {
		this.activeTimeMode = mode;
	}

	onStartTimeRangeChange(act: string) {
		if (this.activeTimeMode !== 'start') {
			this.activeTimeMode = 'start';
		}

		if (this.annotationPlugin && this.pluginReady && this.annotatingMode === true) {
			this.annotationPlugin.fire('startRangeUpdate', { 'act': act });
		}
	}

	onEndTimeRangeChange(act: string) {
		if (this.activeTimeMode !== 'end') {
			this.activeTimeMode = 'end';
		}

		if (this.annotationPlugin && this.pluginReady && this.annotatingMode === true) {
			this.annotationPlugin.fire('endRangeUpdate', { 'act': act });
		}
	}

	// Volume Section //

	updateVolume(value) {
		this.volume = value;
		Amplitude.setVolume(this.volume);
	}

	toggleMute() {
		let volume = this.volume;
		if (this.volume == 0 && !this.muteClick) {
			this.updateVolume(20);
			return;
		}
		if (this.volume > 0) {
			this.updateVolume(0);
			this.muteClick = true
		}
		else if (this.volume == 0) {
			this.updateVolume(this.prevVolume);
			this.muteClick = false;
		}
		this.prevVolume = volume;
	}

	// To set the width of bottom time control elements
	setTimeWidth(el) {
		let cs = getComputedStyle(el);
		return el.offsetWidth - (parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight))
	}

	setTimeHeight(el) { }

	// Update marker comment box left position
	updateMarkerBoxLeft(annotation, el) {
		let offsetMarkerWidth = 5;

		let containerModal = this.waveformContainer.nativeElement.getBoundingClientRect();
		let markerBoxModal = el.getBoundingClientRect();

		let width = markerBoxModal.width;
		let left;
		if (annotation.markerPos === "left") {
			left = (-width - (offsetMarkerWidth * 2)) + 'px';
		}
		else if (annotation.markerPos === "right") {
			left = (el.parentElement.offsetWidth + offsetMarkerWidth) + 'px';
		}

		return left + 42;

		// let markerLeft = markerBoxModal.left;
		// let markerRight = markerBoxModal.right;

		// if (markerLeft < containerModal.left && !annotation.markerLeftSet) {
		// 	annotation.markerLeft = el.offsetLeft + (containerModal.left - markerLeft);
		// 	annotation.markerLeftSet = true;
		// }
		// else if (markerRight > containerModal.right && !annotation.markerLeftSet) {
		// 	annotation.markerLeft = el.offsetLeft - (markerRight - containerModal.right);
		// 	annotation.markerLeftSet = true;
		// }
		// else if (!annotation.markerLeftSet) {
		// 	annotation.markerLeft = el.offsetLeft
		// 	annotation.markerLeftSet = true;
		// }

		// if (annotation.markerLeft) {
		// 	return annotation.markerLeft + 'px';
		// }
	}

	getAvatarLeft(annotation) {
		let parentWidth = this.waveformContainer.nativeElement.offsetWidth;
		let offSet = this.markerAvatarSize / 2;
		if (annotation.markerElement.offsetWidth < 20) {
			offSet = 7;
		}
		let avatarWidthOffset = (100 * offSet) / parentWidth;
		return (annotation.left + annotation.left + annotation.width) / 2 - avatarWidthOffset;
	}

	// TOGGLE ANNOTATION DISPLAY SECTION
	toggleAnnotationDisplay() {
		if (this.annotationPlugin && this.pluginReady) {
			this.annotationPlugin.fire('toggleAnnotationMode');
		}
	}

	downloadAsset() {
		this.downloadButton.downloadFile();
	}

	// PROGRESS MARKER SECTION
	setFirstRowMarkers(args) {
		this.progressBarMarkers = args.firstRowAnnotation;
		if (!args.firstRowAnnotation) return;
		this.progressBarMarkers.forEach(annotation => {
			annotation.left = this.getPercentage(annotation.range.start);
			annotation.width = this.getPercentage(annotation.range.end - annotation.range.start);
		});
	}

	// MARKER WAVEFORM HANDLE SECTION
	activateWaveforms(args) {
		let annotation = this.findAnnotation(args.data.annotation.id);
		this.wavesurferPre.setCurrentTime(annotation.range.start);
		this.wavesurferPost.setCurrentTime(annotation.range.end);

		if (args.data.type === "hover") {
			this.tempTime = this.wavesurfer.getCurrentTime();
			this.wavesurfer.setCurrentTime(annotation.range.start);
		}
		else if (args.data.type === "click") {
			this.tempTime = null;
		}

		let postWidth = annotation.left + annotation.width;


		this.postWaveform.nativeElement.style.right = -postWidth + "%";
		this.postWaveform.nativeElement.firstElementChild.style.left = -postWidth + "%";

		this.waveFormsDisplay = true;
		this.wavesurfer.setWaveColor("white");
	}

	deactivateWaveforms(args) {
		let annotation = this.findAnnotation(args.data.annotation.id);
		if (!(this.tempTime == null)) {
			this.wavesurfer.setCurrentTime(this.tempTime);
			this.tempTime = null;
		}

		this.waveFormsDisplay = false;
		this.wavesurfer.setWaveColor("#909091");
	}

	// Returns annotation object given ID
	findAnnotation(id) {
		return this.annotations.find(a => a.id === id);
	}

	/** Gets the comment input place holder text depending on general or annot comment */
	getPlaceholderText() {
		let placeholderText;
		if (this.annotationPlugin && this.annotationPlugin.controls && (this.annotationPlugin.controls.uiState.adding || this.annotationToolbarActive)) {
			placeholderText = "Write an annotation comment..."
		}
		else {
			placeholderText = "Write a general comment..."
		}

		return placeholderText;
	}

	/** Toggle annotation toolbar display on/off using toggle */
	toggleAnnotationToolbarDisplay(annotationToolbarActive) {
		if (!this.annotationPlugin) {
			return;
		}
		if (annotationToolbarActive == true) {
			this.annotationPlugin.fire("togglePiklorColors", true); // Changes colors in palette to depict greyed out

		}
		else {
			this.canceladdNewAnnotation(); // Triggers canceladdnew event on the plugin
			this.annotationPlugin.fire("togglePiklorColors", false);
		}
	}

	cleanUpFiles() {
		this.newFiles = [];
		this.files = [];
		this.videoRecordFiles = [];
		this.audioRecordFiles = [];
	}

	uploadFileCallback(fileObject) {
		const { files } = fileObject;
		if (!files) {
			return;
		}
		files.forEach(file => {
			if (file.method == 'audioRecord') {
				this.audioRecordFiles.push(file);
			} else if (file.method == 'videoRecord') {
				this.videoRecordFiles.push(file);
			} else {
				this.files.push(file);
			}
		});
		this.newFiles.push(...files);
	}

	onFileDeleted(file) {
		this.newFiles = this.newFiles.filter(f => f.lastModified != file.lastModified);
		this.audioRecordFiles = this.newFiles.filter(f => f.method == 'audioRecord');
		this.videoRecordFiles = this.newFiles.filter(f => f.method == 'videoRecord');
		this.files = this.newFiles.filter(f => !f.method);
	}

	handlePrivateConditionChange(value: AnnotationPrivateCondition) {
		this.privateCondition = value;
		this.annotationService.updatePrivateCondition(value);
	}

	resizeWaveforms() {
		if (this.wavesurfer) {
			this.wavesurfer.empty();
			this.wavesurfer.drawBuffer();
		}
		if (this.wavesurferPre) {
			this.wavesurferPre.empty();
			this.wavesurferPre.drawBuffer();
		}
		if (this.wavesurferPost) {
			this.wavesurferPost.empty();
			this.wavesurferPost.drawBuffer();
		}
	}
}
